import React, { useEffect, useRef, useState } from "react";
import { Flip, ToastContainer } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import { RadioButton } from "primereact/radiobutton";
import FormGroup from "./FormGroup";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import TextField from "@material-ui/core/TextField";
import ButtonMaterialUI from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { FaArrowRight, FaArrowsAltH, FaPhone, FaTimes } from "react-icons/all";

const travelersOptions = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" }
];

const FormButton = withStyles(() => ({
    root: {
        color: "white",
        backgroundColor: "#5eb855",
        "&:hover": {
            backgroundColor: "#5eb855"
        }
    }
}))(ButtonMaterialUI);

function OfferFlights({
    classes,
    countries,
    countryToFlag,
    findDetectedCountry,
    getCityName,
    getDateString,
    suggestFullText,
    suggestTemplate,
    suggest,
    state,
    offerState,
    setOfferState,
    searchState,
    setSearchState,
    subscriptionState,
    editModalShowHandler,
    editModalCloseHandler,
    subscriptionModalCloseHandler,
    subscriptionNameChangeHandler,
    subscriptionCountryChangeHandler,
    subscriptionEmailChangeHandler,
    subscriptionPhoneChangeHandler,
    formOnSubmitHandler,
    subscriptionFormOnSubmitHandler,
    setIsSubmitSuccess,
    isSubmitSuccess,
    clickToCallHandler,
    setIsFormFocus
}) {
    const [countDown, setCountDown] = useState(null);
    const [modalCountDown, setModalCountDown] = useState(null);
    const customInterval = useRef(null);
    const modalInterval = useRef(null);

    const countDownFunction = () => {
        if (countDown > 0) {
            setCountDown(countDown - 1);
        } else {
            clearInterval(customInterval.current);
        }
    };

    const modalCountDownFunction = () => {
        if (modalCountDown > 0) {
            setModalCountDown(modalCountDown - 1);
        } else {
            subscriptionModalCloseHandler();
            clearInterval(modalInterval.current);
        }
    };

    useEffect(() => {
        if (!isNaN(countDown)) {
            customInterval.current = setInterval(countDownFunction, 1000);
        }

        return () => clearInterval(customInterval.current);
    }, [countDown]);

    useEffect(() => {
        if (!isNaN(modalCountDown)) {
            modalInterval.current = setInterval(modalCountDownFunction, 1000);
        }

        return () => clearInterval(modalInterval.current);
    }, [modalCountDown]);

    useEffect(() => {
        if (isSubmitSuccess) {
            setCountDown(60);
        }
    }, [isSubmitSuccess]);

    useEffect(() => {
        if (state.subscriptionModalOpen) {
            setModalCountDown(300);
        }
    }, [state.subscriptionModalOpen]);

    const fromOnChangeHandler = e => {
        setSearchState(prevState => {
            return { ...prevState, source: null, sourceInput: e.value };
        });
    };

    const fromOnSelectHandler = e => {
        setSearchState(prevState => {
            return {
                ...prevState,
                sourceInput: suggestFullText(e.value),
                source: e.value
            };
        });
    };

    const toOnChangeHandler = e => {
        setSearchState(prevState => {
            return {
                ...prevState,
                destination: null,
                destinationInput: e.value
            };
        });
    };

    const toOnSelectHandler = e => {
        setSearchState(prevState => {
            return {
                ...prevState,
                destinationInput: suggestFullText(e.value),
                destination: e.value
            };
        });
    };

    const renderFeatureList = () => {
        return (
            <ul className={"px-5 d-inline-block text-left feature-list"}>
                <li>
                    <>
                        {"Guaranteed Cheap Fares from "}
                        <span>{getCityName(offerState.source)}</span>
                        {" to "}
                        <span>{getCityName(offerState.destination)}</span>
                    </>
                </li>
                <li>
                    Call Waiting Time: <span>Near Zero</span>
                </li>
                <li>All Airlines</li>
            </ul>
        );
    };

    const renderModalCountDown = () => {
        const minute = Math.floor(modalCountDown / 60);
        const seconds = Math.floor(modalCountDown % 60);

        return [
            minute >= 10 ? minute : `0${minute}`,
            ":",
            seconds >= 10 ? seconds : `0${seconds}`
        ].join("");
    };

    return (
        <div className={"callAdContainer"}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
                transition={Flip}
            />
            <div className={"container"} onClick={editModalShowHandler}>
                <Row className={"mt-md-4 mt-1"}>
                    <Col className={"px-2"} xs={4} sm={5}>
                        <InputText
                            className={"w-100"}
                            value={
                                getCityName(offerState.source) +
                                " To " +
                                getCityName(offerState.destination)
                            }
                            onClick={e => {
                                document.body.focus();
                            }}
                        />
                    </Col>
                    <Col
                        className={"px-2"}
                        xs={offerState.tripType === "roundtrip" ? 3 : 6}
                    >
                        <InputText
                            className={"w-100"}
                            value={getDateString(offerState.startDate)}
                            onClick={e => {
                                document.body.focus();
                            }}
                        />
                    </Col>
                    {offerState.tripType === "roundtrip" && (
                        <Col className={"px-2"} xs={3}>
                            <InputText
                                className={"w-100"}
                                value={getDateString(offerState.endDate)}
                                onClick={e => {
                                    document.body.focus();
                                }}
                            />
                        </Col>
                    )}
                    <Col className={"px-2"} xs={2} sm={1}>
                        <Button className={"w-100 h-100"} icon="pi pi-pencil" />
                    </Col>
                </Row>
            </div>

            <Modal
                show={state.editModalOpen}
                onHide={editModalCloseHandler}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className={"text-center"}>
                        Search Flights
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formOnSubmitHandler}>
                        <RadioButton
                            inputId={"rb1"}
                            value="roundtrip"
                            name="tripType"
                            onChange={e =>
                                setSearchState(prevState => {
                                    return {
                                        ...prevState,
                                        tripType: e.value
                                    };
                                })
                            }
                            checked={searchState.tripType === "roundtrip"}
                        />
                        <label htmlFor="rb1" className="p-radiobutton-label">
                            Round Trip
                        </label>
                        <RadioButton
                            className={"ml-3"}
                            inputId={"rb2"}
                            value="oneway"
                            name="tripType"
                            onChange={e =>
                                setSearchState(prevState => {
                                    return {
                                        ...prevState,
                                        tripType: e.value
                                    };
                                })
                            }
                            checked={searchState.tripType === "oneway"}
                        />
                        <label htmlFor="rb2" className="p-radiobutton-label">
                            One-Way
                        </label>

                        <Row className={"mt-3d"}>
                            <Col sm={12} md={6}>
                                <FormGroup title={"From"}>
                                    <AutoComplete
                                        className={
                                            searchState.errors.source
                                                ? "p-error w-100"
                                                : "w-100"
                                        }
                                        inputClassName={"w-100"}
                                        placeholder={"City or airport code"}
                                        value={searchState.sourceInput}
                                        itemTemplate={suggestTemplate}
                                        onChange={fromOnChangeHandler}
                                        onSelect={fromOnSelectHandler}
                                        suggestions={searchState.suggestions}
                                        completeMethod={suggest}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={12} md={6}>
                                <FormGroup title={"To"}>
                                    <AutoComplete
                                        className={
                                            searchState.errors.destination
                                                ? "p-error w-100"
                                                : "w-100"
                                        }
                                        inputClassName={"w-100"}
                                        placeholder={"City or airport code"}
                                        value={searchState.destinationInput}
                                        itemTemplate={suggestTemplate}
                                        onChange={toOnChangeHandler}
                                        onSelect={toOnSelectHandler}
                                        suggestions={searchState.suggestions}
                                        completeMethod={suggest}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup title={"Departing"}>
                                    <Calendar
                                        className={"w-100"}
                                        showIcon={true}
                                        minDate={new Date()}
                                        value={searchState.startDate}
                                        readOnlyInput={true}
                                        onChange={e => {
                                            setSearchState(prevState => {
                                                return {
                                                    ...prevState,
                                                    startDate: e.value
                                                };
                                            });

                                            if (e.value > searchState.endDate) {
                                                setSearchState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        endDate: e.value
                                                    };
                                                });
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            {searchState.tripType === "roundtrip" && (
                                <Col sm={6}>
                                    <FormGroup title={"Returning"}>
                                        <Calendar
                                            className={"w-100"}
                                            showIcon={true}
                                            minDate={searchState.startDate}
                                            value={
                                                searchState.endDate
                                                    ? searchState.endDate
                                                    : searchState.startDate
                                            }
                                            onChange={e =>
                                                setSearchState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        endDate: e.value
                                                    };
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                            )}
                            <Col xs={12} sm={6}>
                                <FormGroup title={"Travelers"}>
                                    <Dropdown
                                        className={"w-100"}
                                        inputClassName={"w-100"}
                                        value={searchState.travelers}
                                        options={travelersOptions}
                                        onChange={e => {
                                            setSearchState(prevState => {
                                                console.log(prevState);
                                                return {
                                                    ...prevState,
                                                    travelers: e.value
                                                };
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <div className={"my-3 d-flex align-items-center"}>
                            <div className={"upTo70"} />
                            <Button
                                label="Update Search"
                                icon="pi pi-search"
                                className="p-button-raised p-button-rounded searchBtn"
                            />
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={state.subscriptionModalOpen}
                onHide={subscriptionModalCloseHandler}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className={"text-center"}>
                        <div className="subscription-modal-header">
                            <div className="color-red">
                                Time Is Running Out: {renderModalCountDown()}
                            </div>
                            Exclusive Deals For Flights From{" "}
                            {getCityName(offerState.source)} To{" "}
                            {offerState.destination
                                ? getCityName(offerState.destination)
                                : "Any Destination"}
                            {offerState.tripType === "oneway"
                                ? " On " +
                                  getDateString(offerState.startDate, "D, M dd")
                                : " From " +
                                  getDateString(
                                      offerState.startDate,
                                      "D, M dd"
                                  ) +
                                  " To " +
                                  getDateString(offerState.endDate, "D, M dd")}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={"text-center"}>
                    <h6>
                        Our VIP concierge team is available now. We have
                        guaranteed cheap fares for flights from{" "}
                        {getCityName(offerState.source)} to{" "}
                        {getCityName(offerState.destination)}. We call you in
                        just 1 minute.
                    </h6>
                    <form onSubmit={subscriptionFormOnSubmitHandler}>
                        <div
                            className={[
                                "form-container",
                                isSubmitSuccess ? "form-expanded" : ""
                            ].join(" ")}
                        >
                            <Row>
                                <Col xs={12} md={6} className={"my-2"}>
                                    <TextField
                                        inputProps={{
                                            autocomplete: "name"
                                        }}
                                        autocomplete="name"
                                        className={"w-100"}
                                        error={subscriptionState.errors.name}
                                        id="outlined-error-helper-text"
                                        label="Name*"
                                        variant="outlined"
                                        onChange={subscriptionNameChangeHandler}
                                        value={subscriptionState.name}
                                        onFocus={() => {
                                            setIsFormFocus(true);
                                        }}
                                        onBlur={() => {
                                            setIsFormFocus(false);
                                        }}
                                    />
                                </Col>
                                <Col md={6} xs={12} className={"my-2"}>
                                    <TextField
                                        inputProps={{
                                            autocomplete: "email"
                                        }}
                                        autocomplete="email"
                                        error={subscriptionState.errors.email}
                                        className={"w-100"}
                                        id="outlined-error-helper-text"
                                        label="Email*"
                                        variant="outlined"
                                        value={subscriptionState.email}
                                        onChange={
                                            subscriptionEmailChangeHandler
                                        }
                                        onFocus={() => {
                                            setIsFormFocus(true);
                                        }}
                                        onBlur={() => {
                                            setIsFormFocus(false);
                                        }}
                                    />
                                </Col>
                                <Col xs={12} md={6} className={"my-2"}>
                                    <TextField
                                        inputProps={{
                                            autocomplete: "tel"
                                        }}
                                        autocomplete="tel"
                                        className={"w-100"}
                                        error={
                                            subscriptionState.errors.phoneNumber
                                        }
                                        id="outlined-error-helper-text"
                                        label="Phone Number*"
                                        variant="outlined"
                                        onChange={
                                            subscriptionPhoneChangeHandler
                                        }
                                        value={subscriptionState.phoneNumber}
                                        onFocus={() => {
                                            setIsFormFocus(true);
                                        }}
                                        onBlur={() => {
                                            setIsFormFocus(false);
                                        }}
                                    />
                                </Col>
                            </Row>

                            <ButtonMaterialUI
                                variant="contained"
                                color="primary"
                                className={"w-100"}
                                style={{
                                    marginTop: "10px",
                                    fontSize: 20 + "px",
                                    fontWeight: "bold"
                                }}
                                onClick={subscriptionFormOnSubmitHandler}
                            >
                                CALL ME NOW
                            </ButtonMaterialUI>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <div className={"container container-box my-md-4 my-1"}>
                <div className={"mob-ad text-center my-4"}>
                    <div className={"search-info text-white p-1"}>
                        <h5 className={"pt-3"}>Deals Found For Flights From</h5>
                        <h2>
                            <span>{getCityName(offerState.source)}</span>
                            <span className={"mx-3"}>
                                {offerState.tripType === "roundtrip" ? (
                                    <FaArrowsAltH />
                                ) : (
                                    <FaArrowRight />
                                )}
                            </span>
                            <span>{getCityName(offerState.destination)}</span>
                        </h2>
                        {offerState.tripType == "roundtrip" ? (
                            <h5 className={"m-0 pb-2"}>
                                <span>
                                    {getDateString(offerState.startDate)}
                                </span>{" "}
                                -{" "}
                                <span>{getDateString(offerState.endDate)}</span>
                            </h5>
                        ) : (
                            <h4 className={"m-0 pb-2"}>
                                <span>
                                    {getDateString(offerState.startDate)}
                                </span>
                            </h4>
                        )}
                    </div>

                    <div className={"caption"}>
                        <div>
                            <h3 className={"py-2  m-0"}>
                                UNPUBLISHED FLIGHT DEALS
                            </h3>
                            <h1 className={"m-0 pb-5 pt-1"}>
                                CALL ONLY DEALS!
                            </h1>
                        </div>
                    </div>

                    <Row>
                        <Col xs={12} md={6}>
                            <div
                                className={
                                    "callDetails mb-4 d-flex justify-content-center align-items-center mx-2"
                                }
                            >
                                <div
                                    className={
                                        "phoneBox d-inline-block p-1 d-flex flex-column text-white"
                                    }
                                    onClick={clickToCallHandler}
                                >
                                    <h1 className={"m-0 p-0"}>
                                        {offerState.phoneNumber}
                                    </h1>
                                    <h6 className={"m-0 p-0"}>Call Now</h6>
                                </div>
                            </div>
                            <div className="desktop-feature-list">
                                {renderFeatureList()}
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="d-flex flex-column justify-content-center p-3 form-container form-body">
                                <div>
                                    {!isSubmitSuccess ? (
                                        <>
                                            {
                                                "Request Callback for Cheap Fares for Flights from "
                                            }
                                            {getCityName(offerState.source)}
                                            {" to "}
                                            {getCityName(
                                                offerState.destination
                                            )}
                                            <div>
                                                <span className="color-red">
                                                    We Call You in 1 Minute
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                Your request is submitted
                                                successfully.
                                            </div>
                                            Our team will call you in{" "}
                                            <span className="color-red">
                                                {countDown} {" Seconds"}
                                            </span>
                                        </>
                                    )}
                                </div>
                                <div
                                    className={[
                                        "form-container",
                                        isSubmitSuccess ? "form-expanded" : ""
                                    ].join(" ")}
                                >
                                    <form
                                        onSubmit={
                                            subscriptionFormOnSubmitHandler
                                        }
                                    >
                                        <div className="row mx-0">
                                            <Col
                                                xs={12}
                                                md={6}
                                                className={"my-2 px-2"}
                                            >
                                                <TextField
                                                    inputProps={{
                                                        autocomplete: "name"
                                                    }}
                                                    autocomplete="name"
                                                    className={"w-100"}
                                                    error={
                                                        subscriptionState.errors
                                                            .name
                                                    }
                                                    id="outlined-error-helper-text"
                                                    label="Name*"
                                                    variant="outlined"
                                                    onChange={
                                                        subscriptionNameChangeHandler
                                                    }
                                                    value={
                                                        subscriptionState.name
                                                    }
                                                    onFocus={() => {
                                                        setIsFormFocus(true);
                                                    }}
                                                    onBlur={() => {
                                                        setIsFormFocus(false);
                                                    }}
                                                />
                                            </Col>
                                            <Col
                                                xs={12}
                                                md={6}
                                                className={"my-2 px-2"}
                                            >
                                                <TextField
                                                    inputProps={{
                                                        autocomplete: "email"
                                                    }}
                                                    autocomplete="email"
                                                    error={
                                                        subscriptionState.errors
                                                            .email
                                                    }
                                                    className={"w-100"}
                                                    id="outlined-error-helper-text"
                                                    label="Email Address*"
                                                    variant="outlined"
                                                    value={
                                                        subscriptionState.email
                                                    }
                                                    onChange={
                                                        subscriptionEmailChangeHandler
                                                    }
                                                    onFocus={() => {
                                                        setIsFormFocus(true);
                                                    }}
                                                    onBlur={() => {
                                                        setIsFormFocus(false);
                                                    }}
                                                />
                                            </Col>
                                            <Col
                                                xs={12}
                                                md={6}
                                                className={"my-2 px-2"}
                                            >
                                                <TextField
                                                    inputProps={{
                                                        autocomplete: "tel"
                                                    }}
                                                    autocomplete="tel"
                                                    className={"w-100"}
                                                    error={
                                                        subscriptionState.errors
                                                            .phoneNumber
                                                    }
                                                    id="outlined-error-helper-text"
                                                    label="Phone Number*"
                                                    variant="outlined"
                                                    onChange={
                                                        subscriptionPhoneChangeHandler
                                                    }
                                                    value={
                                                        subscriptionState.phoneNumber
                                                    }
                                                    onFocus={() => {
                                                        setIsFormFocus(true);
                                                    }}
                                                    onBlur={() => {
                                                        setIsFormFocus(false);
                                                    }}
                                                />
                                            </Col>
                                        </div>
                                        <div className="row mx-0">
                                            <Col
                                                xs={12}
                                                md={12}
                                                className={"my-2 px-2"}
                                            >
                                                <FormButton
                                                    variant="contained"
                                                    color="primary"
                                                    className={"w-100"}
                                                    style={{
                                                        fontSize: 20 + "px",
                                                        fontWeight: "bold"
                                                    }}
                                                    onClick={
                                                        subscriptionFormOnSubmitHandler
                                                    }
                                                >
                                                    CALL ME NOW
                                                </FormButton>
                                            </Col>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="mobile-feature-list">
                                {renderFeatureList()}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            {offerState.footerCallBoxShown && (
                <div
                    className={
                        "footer-call-box d-flex justify-content-between align-items-center position-fixed w-100"
                    }
                >
                    <FaTimes
                        onClick={() => {
                            setOfferState(prevState => {
                                return {
                                    ...prevState,
                                    footerCallBoxShown: false
                                };
                            });
                        }}
                        className={"mx-3"}
                    />
                    <h6 className={"call-text p-0 m-0"}>
                        <span onClick={clickToCallHandler}>
                            {offerState.phoneNumber}
                        </span>
                        : Unpublished Flight Rates from{" "}
                        {getCityName(offerState.source)} to{" "}
                        {getCityName(offerState.destination)}
                    </h6>
                    <div className={"call-btn"} onClick={clickToCallHandler}>
                        <FaPhone
                            className={"phone-icon"}
                            onClick={clickToCallHandler}
                        />
                    </div>
                </div>
            )}
            {!offerState.footerCallBoxShown && (
                <div
                    className={"call-btn call-btn-alone"}
                    onClick={clickToCallHandler}
                >
                    <FaPhone
                        className={"phone-icon"}
                        onClick={clickToCallHandler}
                    />
                </div>
            )}
        </div>
    );
}

export default OfferFlights;
