import React, { useEffect, useRef, useState } from "react";
import rocketImg from "../assets/images/rocket-icon.svg";
import { Col, Row } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { TextField } from "@material-ui/core";
import { Button } from "primereact/button";
import axios from "axios";
import Footer from "./footer";

function ContactUs() {
    const [isFormFocus, setIsFormFocus] = useState(false);
    const isFormFocusRef = useRef(false);

    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        isFormFocusRef.current = isFormFocus;
    }, [isFormFocus]);
    const [contactState, setContactState] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        message: ""
    });
    const contactNameChangeHandler = event => {
        const { target } = event;
        setContactState(prevState => {
            return { ...prevState, name: target.value };
        });
    };
    const contactEmailChangeHandler = event => {
        const { target } = event;
        setContactState(prevState => {
            return { ...prevState, email: target.value };
        });
    };
    const contactNumberChangeHandler = event => {
        const { target } = event;
        setContactState(prevState => {
            return { ...prevState, phoneNumber: target.value };
        });
    };
    const contactMessageChangeHandler = event => {
        const { target } = event;
        setContactState(prevState => {
            return { ...prevState, message: target.value };
        });
    };
    const handleContactUs = () => {
        setShowSuccess(false);
        axios
            .post("https://nextjs-rta.vercel.app/api/sign-up/validate-username/test", contactState)
            .then(response => {
                setShowSuccess(true);
            });
    };
    return (
        <>
            <div className="container mt-4">
                <h1 className="text-center text-primary">
                    <img className="my-5" src={rocketImg} width={30} alt="rocket" />
                </h1>
                <h4 className="text-center text-primary">
                    Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to
                    you within a matter of hours to help you.
                </h4>

                {
                    showSuccess && <div className="alert alert-success" role="alert">
                        Thanks for contacting us!
                    </div>
                }
                <Row>
                    <Col xs={6}>
                        <Row>
                            <Col xs={12} md={12} className={"my-2"}>
                                <TextField
                                    inputProps={{
                                        autocomplete: "name"
                                    }}
                                    autocomplete="name"
                                    className={"w-100"}
                                    id="outlined-error-helper-text"
                                    label="Name*"
                                    variant="outlined"
                                    onChange={contactNameChangeHandler}
                                    value={contactState.name}
                                />
                            </Col>
                            <Col md={12} xs={12} className={"my-2"}>
                                <TextField
                                    inputProps={{
                                        autocomplete: "email"
                                    }}
                                    autocomplete="email"
                                    className={"w-100"}
                                    id="outlined-error-helper-text"
                                    label="Email*"
                                    variant="outlined"
                                    value={contactState.email}
                                    onChange={
                                        contactEmailChangeHandler
                                    }
                                />
                            </Col>
                            <Col xs={12} md={12} className={"my-2"}>
                                <TextField
                                    inputProps={{
                                        autocomplete: "tel"
                                    }}
                                    autocomplete="tel"
                                    className={"w-100"}
                                    id="outlined-error-helper-text"
                                    label="Phone Number*"
                                    variant="outlined"
                                    onChange={
                                        contactNumberChangeHandler
                                    }
                                    value={contactState.phoneNumber}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6}>
                        <Row className="h-100">
                            <Col xs={12} md={12} className={"my-2"}>
                                <TextField
                                    inputProps={{
                                        autocomplete: "message"
                                    }}
                                    autocomplete="message"
                                    multiline
                                    rows={8}
                                    className={"w-100 h-100"}
                                    id="outlined-error-helper-text"
                                    label="Message*"
                                    variant="outlined"
                                    onChange={contactMessageChangeHandler}
                                    value={contactState.message}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-end">
                    <Button
                        label="Send Message"
                        className="p-button-rounded contact-us-btn"
                        onClick={() => {
                            handleContactUs();
                        }}
                    />
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ContactUs;
