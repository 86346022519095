import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";

function TopHeader(props) {
    return (
        <Navbar
            id={"topHeader"}
            collapseOnSelect
            expand="lg"
            variant="light"
            className={"container"}
            expanded={props.navExpanded}
            onToggle={props.setNavExpanded}
        >
            <Navbar.Brand className={"mr-5"}>
                <div className={"d-flex align-items-center"}>
                    <Link to={"/"}>CallToTravel.com</Link>
                </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <NavLink
                        onClick={() => {
                            props.setNavExpanded(false);
                        }}
                        className={"nav-link"}
                        to="/search/flights"
                    >
                        FLIGHTS
                    </NavLink>
                    <NavLink
                        onClick={() => {
                            props.setNavExpanded(false);
                        }}
                        className={"nav-link"}
                        to="/search/hotels"
                    >
                        HOTELS
                    </NavLink>
                    <NavLink
                        onClick={() => {
                            props.setNavExpanded(false);
                        }}
                        className={"nav-link"}
                        to="/search/cruises"
                    >
                        CRUISES
                    </NavLink>
                    <NavLink
                        onClick={() => {
                            props.setNavExpanded(false);
                        }}
                        className={"nav-link"}
                        to="/search/cars"
                    >
                        CAR RENTALS
                    </NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default TopHeader;
