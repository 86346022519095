import React, { useReducer, useState } from "react";
import { TabMenu } from "primereact/tabmenu";
import { FaPlane, FaCar, FaHotel, FaShip } from "react-icons/all";
import TopTabMenuItem from "./TopTabMenuItem";
import { Col, Row } from "react-bootstrap";

import { IconContext } from "react-icons";

const initialState = {
    items: [
        {
            sectionName: "Flights",
            sectionLink: "flights",
            sectionIcon: <FaPlane />
        },
        {
            sectionName: "Hotels",
            sectionLink: "hotels",
            sectionIcon: <FaHotel />
        },
        {
            sectionName: "Cruises",
            sectionLink: "cruises",
            sectionIcon: <FaShip />
        },
        { sectionName: "Cars", sectionLink: "cars", sectionIcon: <FaCar /> }
    ]
};

function TopTabMenu(props) {
    const [state, setState] = useState(initialState);

    const topTabMenuItems = state.items.map(item => (
        <TopTabMenuItem
            key={item.sectionName}
            sectionLink={item.sectionLink}
            sectionName={item.sectionName}
            sectionIcon={item.sectionIcon}
            onClick={() => {
                setState({ ...state, activeSection: item.sectionName });
            }}
        />
    ));

    return (
        <Row noGutters={true} className={"text-center mb-4 align-items-center"}>
            <Col lg={4}>
                <IconContext.Provider value={{ color: "white", size: "3rem" }}>
                    <Row id={"topTabMenu"} noGutters={true}>
                        {topTabMenuItems}
                    </Row>
                </IconContext.Provider>
            </Col>
            <Col className={"d-none d-lg-block"}>
                <h3 className={"px-3 m-0"}>
                    Price comparisons on flights, hotels, cruises and car
                    rentals
                </h3>
            </Col>
        </Row>
    );
}

export default TopTabMenu;
