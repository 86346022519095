import React from "react";

function FormGroup(props) {
    return (
        <>
            <h6 className={props.className + " mt-3"}>{props.title}</h6>
            {props.children}
        </>
    );
}

export default FormGroup;
