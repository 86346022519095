import React, { useState, useEffect } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Spinner } from "primereact/spinner";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FaSpinner, FaCity, MdLocalAirport } from "react-icons/all";
import axios from "axios";
import FormGroup from "./FormGroup";
import { Redirect } from "react-router-dom";

import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

const todayDate = new Date();

function getUrlVars() {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
        m,
        key,
        value
    ) {
        vars[key] = value;
    });
    return vars;
}

const addDaysToDate = (date, days) => {
    let aux = new Date(date);
    return new Date(aux.setTime(aux.getTime() + days * 24 * 60 * 60 * 1000));
};

const getDateString = date => {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
        dd = "0" + dd;
    }

    if (mm < 10) {
        mm = "0" + mm;
    }
    return mm + "/" + dd + "/" + yyyy;
};

const getIATACode = sug => {
    return sug.iata_city ? sug.iata_city : sug.iata_code;
};

const travelersOptions = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" }
];
const initialState = {
    tripType: "roundtrip",

    startMinDate: todayDate,
    startDate: addDaysToDate(todayDate, 7),
    endDate: addDaysToDate(todayDate, 10),

    errors: {},

    travelers: travelersOptions[0].value
};

const suggestTemplate = sug => {
    if (sug.isLoader)
        return (
            <div className={"text-center"}>
                <FaSpinner className={"icon-spin"} />
            </div>
        );
    return (
        <>
            {sug.iata_city ? (
                <>
                    <FaCity className={"mr-1"} /> {sug.iata_city}{" "}
                </>
            ) : (
                <>
                    <MdLocalAirport className={"mr-1"} /> {sug.iata_code}{" "}
                </>
            )}{" "}
            - {sug.city_name ? sug.city_name : sug.airport_name},{" "}
            {sug.country_code}
        </>
    );
};

const suggestFullText = sug => {
    return (
        "" +
        (sug.iata_city ? sug.iata_city : sug.iata_code) +
        " - " +
        (sug.city_name ? sug.city_name : sug.airport_name) +
        ", " +
        sug.country_code
    );
};

function HomeFlights(props) {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        document.title = "Call to Find Cheap Flights to Book";
        ReactPixel.pageView();
        ReactGA.pageview(window.location.pathname + window.location.search);

        let params = getUrlVars();
        let newState = {};

        if (params.startDate) {
            newState.startDate = new Date(params.startDate);
        }
        if (params.endDate) {
            newState.endDate = new Date(params.endDate);
        }
        if (params.travelers) {
            newState.travelers = params.travelers;
        }
        if (params.tripType) {
            newState.tripType = params.tripType;
        }

        setState(prevState => {
            return {
                ...prevState,
                ...newState
            };
        });

        if (params.source) {
            axios
                .get("https://calltotravel.com/api/findCity?q=" + params.source)
                .then(response => {
                    if (response.data[0]) {
                        setState(prevState => {
                            return {
                                ...prevState,
                                source: response.data[0],
                                sourceInput: suggestFullText(response.data[0])
                            };
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }

        if (params.destination) {
            axios
                .get(
                    "https://calltotravel.com/api/findCity?q=" +
                        params.destination
                )
                .then(response => {
                    if (response.data[0]) {
                        setState(prevState => {
                            return {
                                ...prevState,
                                destination: response.data[0],
                                destinationInput: suggestFullText(
                                    response.data[0]
                                )
                            };
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, []);

    const suggest = event => {
        setState(prevState => {
            return {
                ...prevState,
                suggestions: [
                    {
                        isLoader: true
                    }
                ]
            };
        });
        let results = [];
        axios
            .get("https://calltotravel.com/api/findCity?q=" + event.query)
            .then(response => {
                console.log(response);
                results = response.data;
                setState(prevState => {
                    return { ...prevState, suggestions: results };
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    const fromOnChangeHandler = e => {
        setState(prevState => {
            return { ...prevState, sourceInput: e.value, source: null };
        });
    };

    const fromOnSelectHandler = e => {
        setState(prevState => {
            return {
                ...prevState,
                sourceInput: suggestFullText(e.value),
                source: e.value
            };
        });
    };

    const toOnChangeHandler = e => {
        setState(prevState => {
            return {
                ...prevState,
                destinationInput: e.value,
                destination: null
            };
        });
    };

    const toOnSelectHandler = e => {
        setState(prevState => {
            return {
                ...prevState,
                destinationInput: suggestFullText(e.value),
                destination: e.value
            };
        });
    };

    const formOnSubmitHandler = e => {
        e.preventDefault();

        var errors = {};

        if (!state.source) {
            errors.source = true;
        } else {
            delete errors.source;
        }

        if (!state.source) {
            errors.destination = true;
        } else {
            delete errors.destination;
        }

        setState(prevState => {
            return {
                ...prevState,
                errors
            };
        });

        if (!errors.source && !errors.destination) {
            ReactGA.event({
                category: "Front",
                action: "Click"
            });

            ReactPixel.track("Search", []);

            setState(prevState => {
                return {
                    ...prevState,
                    isLoading: true
                };
            });

            let searchDetails = {
                page: "flights",
                tripType: state.tripType,
                source: getIATACode(state.source),
                destination: getIATACode(state.destination),
                startDate: getDateString(state.startDate),
                endDate: getDateString(state.endDate),
                travelers: state.travelers
            };

            console.log(searchDetails);

            axios
                .post(
                    "https://calltotravel.com/api/searches/add",
                    searchDetails
                )
                .then(response => {
                    console.log(response);
                    console.log(response.data);
                    setState(prevState => {
                        return { ...prevState, changeOfferId: response.data };
                    });
                });
        }
    };

    return state.changeOfferId ? (
        <Redirect to={"/offer?id=" + state.changeOfferId} push={true} />
    ) : (
        <form onSubmit={formOnSubmitHandler}>
            <h1 className={"shadowed"}>Search Flights</h1>
            <RadioButton
                inputId={"rb1"}
                value="roundtrip"
                name="flightType"
                onChange={e =>
                    setState(prevState => {
                        return { ...prevState, tripType: e.value };
                    })
                }
                checked={state.tripType === "roundtrip"}
            />
            <label htmlFor="rb1" className="p-radiobutton-label shadowed">
                Round Trip
            </label>
            <RadioButton
                className={"ml-3"}
                inputId={"rb2"}
                value="oneway"
                name="city"
                onChange={e =>
                    setState(prevState => {
                        return { ...prevState, tripType: e.value };
                    })
                }
                checked={state.tripType === "oneway"}
            />
            <label htmlFor="rb2" className="p-radiobutton-label shadowed">
                One-Way
            </label>

            <Row className={"mt-4"}>
                <Col lg={4}>
                    <Row>
                        <Col md={6}>
                            <FormGroup title={"From"}>
                                <AutoComplete
                                    className={
                                        state.errors.source
                                            ? "p-error w-100"
                                            : "w-100"
                                    }
                                    inputClassName={"w-100"}
                                    placeholder={"City or airport code"}
                                    value={state.sourceInput}
                                    itemTemplate={suggestTemplate}
                                    onChange={fromOnChangeHandler}
                                    onSelect={fromOnSelectHandler}
                                    suggestions={state.suggestions}
                                    completeMethod={suggest}
                                />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup title={"To"}>
                                <AutoComplete
                                    className={
                                        state.errors.destination
                                            ? "p-error w-100"
                                            : "w-100"
                                    }
                                    inputClassName={"w-100"}
                                    placeholder={"City or airport code"}
                                    value={state.destinationInput}
                                    itemTemplate={suggestTemplate}
                                    onChange={toOnChangeHandler}
                                    onSelect={toOnSelectHandler}
                                    suggestions={state.suggestions}
                                    completeMethod={suggest}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>

                <Col xs={6} lg={2}>
                    <FormGroup title={"Departing"}>
                        <Calendar
                            className={"w-100"}
                            showIcon={true}
                            minDate={state.startMinDate}
                            value={state.startDate}
                            readOnlyInput={true}
                            onChange={e => {
                                setState(prevState => {
                                    return { ...prevState, startDate: e.value };
                                });

                                if (e.value > state.endDate) {
                                    setState(prevState => {
                                        return {
                                            ...prevState,
                                            endDate: e.value
                                        };
                                    });
                                }
                            }}
                        />
                    </FormGroup>
                </Col>
                {state.tripType === "roundtrip" && (
                    <Col xs={6} lg={2}>
                        <FormGroup title={"Returning"}>
                            <Calendar
                                className={"w-100"}
                                showIcon={true}
                                minDate={state.startDate}
                                value={state.endDate}
                                onChange={e =>
                                    setState(prevState => {
                                        return {
                                            ...prevState,
                                            endDate: e.value
                                        };
                                    })
                                }
                            />
                        </FormGroup>
                    </Col>
                )}
                <Col xs={12} sm={6} lg={2}>
                    <FormGroup title={"Travelers"}>
                        <Dropdown
                            className={"w-100"}
                            inputClassName={"w-100"}
                            value={state.travelers}
                            options={travelersOptions}
                            onChange={e => {
                                setState(prevState => {
                                    return { ...prevState, travelers: e.value };
                                });
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} sm={6} lg={2}></Col>
            </Row>

            <Button
                label="Search"
                icon="pi pi-search"
                className="p-button-raised p-button-rounded my-5 searchBtn"
            />
        </form>
    );
}

export default HomeFlights;
