import React, { useEffect, useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Spinner } from "primereact/spinner";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FaSpinner, FaCity, MdLocalAirport } from "react-icons/all";
import axios from "axios";
import FormGroup from "./FormGroup";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { Redirect } from "react-router-dom";

const destinations = [
    { label: "Any Destination", value: "" },
    { label: "Africa", value: "Africa" },
    { label: "Alaska", value: "Alaska" },
    { label: "Asia", value: "Asia" },
    { label: "Australia / New Zealand", value: "Australia / New Zealand" },
    { label: "Bahamas", value: "Bahamas" },
    { label: "Bermuda", value: "Bermuda" },
    { label: "Canada / New England", value: "Canada / New England" },
    { label: "Caribbean", value: "Caribbean" },
    { label: "Central America", value: "Central America" },
    { label: "Europe", value: "Europe" },
    { label: "Hawaii", value: "Hawaii" },
    { label: "Mexico", value: "Mexico" },
    { label: "Middle East", value: "Middle East" },
    { label: "Multi-Country Rivers", value: "Multi-Country Rivers" },
    { label: "Other", value: "Other" },
    { label: "Pacific Coastal", value: "Pacific Coastal" },
    { label: "Panama Canal", value: "Panama Canal" },
    { label: "South America", value: "South America" },
    { label: "South Pacific", value: "South Pacific" },
    { label: "Transatlantic", value: "Transatlantic" }
];

const getDepartures = () => {
    let dates = [new Date()];
    for (let i = 1; i < 24; i++) {
        let date = new Date();
        date.setMonth(date.getMonth() + i);
        date.setDate(1);
        dates.push(date);
    }

    return dates;
};

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

const getDateString = (date, format = "mm/dd/yyyy") => {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
        dd = "0" + dd;
    }

    if (mm < 10) {
        mm = "0" + mm;
    }
    switch (format) {
        case "mm/dd/yyyy":
            return mm + "/" + dd + "/" + yyyy;
        case "dd/mm/yyyy":
            return dd + "/" + mm + "/" + yyyy;
    }
};

const getDeparturesOptions = () => {
    let dates = getDepartures();
    let departures = [{ label: "Any Time", value: "" }];
    for (let i = 0; i < dates.length; i++) {
        departures.push({
            label: months[dates[i].getMonth()] + " " + dates[i].getFullYear(),
            value: getDateString(dates[i])
        });
    }
    return departures;
};

const cruiseLines = [
    { label: "Any Cruise Line", value: "" },
    { label: "A-Rosa", value: "A-Rosa" },
    { label: "Abercrombie & Kent", value: "Abercrombie & Kent" },
    { label: "AIDA Cruises", value: "AIDA Cruises" },
    { label: "AmaWaterways", value: "AmaWaterways" },
    { label: "American Cruise Line", value: "American Cruise Line" },
    { label: "APT River Cruises", value: "APT River Cruises" },
    { label: "Avalon Waterways", value: "Avalon Waterways" },
    { label: "Azamara Club Cruises", value: "Azamara Club Cruises" },
    {
        label: "Bahamas Paradise Cruise Line",
        value: "Bahamas Paradise Cruise Line"
    },
    {
        label: "Blount - Small Ship Adventures",
        value: "Blount - Small Ship Adventures"
    },
    { label: "Carnival Cruise Lines", value: "Carnival Cruise Lines" },
    { label: "Celebration Cruise Line", value: "Celebration Cruise Line" },
    { label: "Celebrity Cruises", value: "Celebrity Cruises" },
    { label: "Celestyal Cruises", value: "Celestyal Cruises" },
    { label: "Costa Cruise Lines", value: "Costa Cruise Lines" },
    { label: "CroisiEurope", value: "CroisiEurope" },
    { label: "Cruceros Australis", value: "Cruceros Australis" },
    { label: "Crystal Cruises", value: "Crystal Cruises" },
    { label: "Cunard Cruises", value: "Cunard Cruises" },
    { label: "Disney Cruise Line", value: "Disney Cruise Line" },
    { label: "Emerald Waterways", value: "Emerald Waterways" },
    { label: "Fathom", value: "Fathom" },
    { label: "Fred Olsen Cruise Lines", value: "Fred Olsen Cruise Lines" },
    {
        label: "Galapagos Tours and Cruises",
        value: "Galapagos Tours and Cruises"
    },
    { label: "Haimark", value: "Haimark" },
    { label: "Hapag-Lloyd", value: "Hapag-Lloyd" },
    { label: "Holland America Line", value: "Holland America Line" },
    { label: "Hurtigruten", value: "Hurtigruten" },
    {
        label: "Lindblad Special Expeditions",
        value: "Lindblad Special Expeditions"
    },
    { label: "MSC Cruises", value: "MSC Cruises" },
    { label: "Norwegian Cruise Line", value: "Norwegian Cruise Line" },
    { label: "Ocean Hunter Palau", value: "Ocean Hunter Palau" },
    { label: "Ocean Star Cruises", value: "Ocean Star Cruises" },
    { label: "Oceania Cruises", value: "Oceania Cruises" },
    { label: "P & O Cruises", value: "P & O Cruises" },
    { label: "P&O Australia", value: "P&O Australia" },
    { label: "Paul Gauguin Cruises", value: "Paul Gauguin Cruises" },
    { label: "Ponant Yacht Cruises", value: "Ponant Yacht Cruises" },
    { label: "Princess Cruises", value: "Princess Cruises" },
    { label: "Pullmantur Cruises", value: "Pullmantur Cruises" },
    { label: "Quark Expeditions", value: "Quark Expeditions" },
    { label: "Quasar Nautica", value: "Quasar Nautica" },
    { label: "Regent Seven Seas", value: "Regent Seven Seas" },
    { label: "Riviera River Cruises", value: "Riviera River Cruises" },
    {
        label: "Royal Caribbean International",
        value: "Royal Caribbean International"
    },
    { label: "Scenic Cruises", value: "Scenic Cruises" },
    { label: "Sea Cloud Cruises", value: "Sea Cloud Cruises" },
    { label: "Seabourn Cruise Line", value: "Seabourn Cruise Line" },
    { label: "SeaDream Yacht Club", value: "SeaDream Yacht Club" },
    { label: "Silversea Cruises", value: "Silversea Cruises" },
    { label: "Star Clippers", value: "Star Clippers" },
    { label: "Swan Hellenic Cruises", value: "Swan Hellenic Cruises" },
    { label: "Tauck Tours", value: "Tauck Tours" },
    { label: "The Boat Company", value: "The Boat Company" },
    { label: "TUI Cruises", value: "TUI Cruises" },
    { label: "U By Uniworld", value: "U By Uniworld" },
    { label: "Un-Cruise Adventures", value: "Un-Cruise Adventures" },
    { label: "Uniworld Cruises", value: "Uniworld Cruises" },
    { label: "Variety Cruises", value: "Variety Cruises" },
    { label: "Viking Cruises", value: "Viking Cruises" },
    { label: "Viking River Cruises", value: "Viking River Cruises" },
    { label: "Virgin Voyages", value: "Virgin Voyages" },
    { label: "Voyages of Discovery", value: "Voyages of Discovery" },
    { label: "Windstar Cruises", value: "Windstar Cruises" }
];

const lengths = [
    { label: "Any Length", value: "" },
    { label: "1-2 Nights", value: "1-2" },
    { label: "3-5 Nights", value: "3-5" },
    { label: "6-9 Nights", value: "6-9" },
    { label: "10+ Nights", value: "10+" }
];

const initialState = {
    destination: destinations[0].value,
    //departure: departures[0].value,
    cruiseLine: cruiseLines[0].value,
    length: lengths[0].value
};

function HomeCruises(props) {
    const departuresOptions = getDeparturesOptions();
    initialState.departure = departuresOptions[0].value;

    const [state, setState] = useState(initialState);

    useEffect(() => {
        document.title = "Call to Find Cheap Cruises to Book";
    }, []);

    const formSubmitHandler = e => {
        e.preventDefault();

        ReactGA.event({
            category: "Front",
            action: "Click"
        });
        ReactPixel.track("Search", []);

        setState(prevState => {
            return {
                ...prevState,
                isLoading: true
            };
        });

        let searchDetails = {
            page: "cruises",
            destination: state.destination,
            departure: state.departure,
            cruiseLine: state.cruiseLine,
            length: state.length
        };

        axios
            .post("https://callToTravel.com/api/searches/add", searchDetails)
            .then(response => {
                setState(prevState => {
                    return { ...prevState, changeOfferId: response.data };
                });
            });
    };

    return state.changeOfferId ? (
        <Redirect to={"/offer?id=" + state.changeOfferId} push={true} />
    ) : (
        <form onSubmit={formSubmitHandler}>
            <h1 className={"shadowed"}>Search Cruises</h1>

            <Row className={"mt-4"}>
                <Col xs={12} sm={6} lg={3}>
                    <FormGroup title={"Destination"}>
                        <Dropdown
                            className={"w-100"}
                            inputClassName={"w-100"}
                            value={state.destination}
                            options={destinations}
                            onChange={e => {
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        destination: e.value
                                    };
                                });
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                    <FormGroup title={"Departure"}>
                        <Dropdown
                            className={"w-100"}
                            inputClassName={"w-100"}
                            value={state.departure}
                            options={departuresOptions}
                            onChange={e => {
                                setState(prevState => {
                                    return { ...prevState, departure: e.value };
                                });
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                    <FormGroup title={"Cruise Line"}>
                        <Dropdown
                            className={"w-100"}
                            inputClassName={"w-100"}
                            value={state.cruiseLine}
                            options={cruiseLines}
                            onChange={e => {
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        cruiseLine: e.value
                                    };
                                });
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                    <FormGroup title={"Length"}>
                        <Dropdown
                            className={"w-100"}
                            inputClassName={"w-100"}
                            value={state.length}
                            options={lengths}
                            onChange={e => {
                                setState(prevState => {
                                    return { ...prevState, length: e.value };
                                });
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Button
                label="Search"
                icon="pi pi-search"
                className="p-button-raised p-button-rounded my-5 searchBtn"
            />
        </form>
    );
}

export default HomeCruises;
