import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function Footer(props) {
    return (
        <footer className={"mt-3"}>
            <div className="container">
                <div className="footer-inner">
                    <div className="copyright-container">
                        <p>© Copyright 2019-2023 <strong>CallToTravel.com</strong></p>
                    </div>
                    <div className="footer-menu">
                        <ul>
                            <li>
                                <Link to={"/privacypolicy"}>Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to={"/contactus"}>Contact Us</Link>
                            </li>
                            <li>
                                <Link to={"/termsofservice"}>Terms of Service</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

Footer.propTypes = {};

export default Footer;
