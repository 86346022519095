import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

import { FaCity, FaSpinner, MdLocalAirport } from "react-icons/all";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OfferFlights from "./OfferFlights";
import OfferHotels from "./OfferHotels";
import OfferCruises from "./OfferCruises";
import OfferCars from "./OfferCars";
import { SetNavExpandedContext } from "../App";

const initialState = {
    isLoading: true,
    editModalOpen: false,
    subscriptionModalOpen: false
};

const suggestTemplate = sug => {
    if (sug.isLoader)
        return (
            <div className={"text-center"}>
                <FaSpinner className={"icon-spin"} />
            </div>
        );
    return (
        <>
            {sug.iata_city ? (
                <>
                    <FaCity className={"mr-1"} /> {sug.iata_city}{" "}
                </>
            ) : (
                <>
                    <MdLocalAirport className={"mr-1"} /> {sug.iata_code}{" "}
                </>
            )}{" "}
            - {sug.city_name ? sug.city_name : sug.airport_name},{" "}
            {sug.country_code}
        </>
    );
};

const suggestFullText = sug => {
    return (
        "" +
        (sug.iata_city ? sug.iata_city : sug.iata_code) +
        " - " +
        (sug.city_name ? sug.city_name : sug.airport_name) +
        ", " +
        sug.country_code
    );
};

const getCityName = sug => {
    return sug.city_name ? sug.city_name : sug.airport_name;
};

const getIATACode = sug => {
    return sug.iata_city ? sug.iata_city : sug.iata_code;
};

const getDateString = (date, format = "mm/dd/yyyy") => {
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ];

    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    let H = date.getHours();
    let i = date.getMinutes();
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    let M = months[mm - 1];
    let D = days[date.getDay()];
    if (H < 10) {
        H = "0" + H;
    }

    if (i < 10) {
        i = "0" + i;
    }

    if (dd < 10) {
        dd = "0" + dd;
    }

    if (mm < 10) {
        mm = "0" + mm;
    }
    switch (format) {
        case "mm/dd/yyyy":
            return mm + "/" + dd + "/" + yyyy;
        case "dd/mm/yyyy":
            return dd + "/" + mm + "/" + yyyy;
        case "D, M dd":
            return D + ", " + M + " " + dd;
        case "H:i":
            return H + ":" + i;
    }
};

function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== "undefined"
        ? isoCode
              .toUpperCase()
              .replace(/./g, char =>
                  String.fromCodePoint(char.charCodeAt(0) + 127397)
              )
        : isoCode;
}

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18
        }
    },
    button: {
        //  margin: theme.spacing(1)
    }
});

function getUrlVars(query) {
    var vars = {};
    query.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
        vars[key] = value;
    });
    return vars;
}

function Offer2({ location }) {
    //let { offerID } = useParams();

    const setNavExpanded = useContext(SetNavExpandedContext);

    let params = getUrlVars(location.search);
    let offerID = params.id;

    const [state, setState] = useState(initialState);

    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
    const [isFormFocus, setIsFormFocus] = useState(false);

    const isSubmitSuccessRef = useRef(false);
    const isFormFocusRef = useRef(false);

    useEffect(() => {
        isFormFocusRef.current = isFormFocus;
    }, [isFormFocus]);

    const [searchState, setSearchState] = useState({ errors: {} });

    const [offerState, setOfferState] = useState();

    const [subscriptionState, setSubscriptionState] = useState({
        searchId: "",
        country: "",
        name: "",
        phoneNumber: "",
        email: "",
        errors: {}
    });

    const subscribeNotifyError = () => {
        toast.error("There's an error! please try again.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {
                setState(prevState => {
                    return { ...prevState, subscriptionModalOpen: true };
                });
            }
        });
    };

    const subscribeNotifySending = () => {
        toast.info("Submitting The Request ...", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
    };

    const subscribeNotifySuccess = () => {
        toast.success("Done.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
    };

    const subscriptionNameChangeHandler = event => {
        const { target } = event;
        setSubscriptionState(prevState => {
            return { ...prevState, name: target.value };
        });
    };

    const subscriptionPhoneChangeHandler = event => {
        const { target } = event;
        setSubscriptionState(prevState => {
            return { ...prevState, phoneNumber: target.value };
        });
    };

    const subscriptionCountryChangeHandler = event => {
        const { target } = event;
        let countryCode = "";
        if (target) {
            let countryCodeSpan = target.querySelector(
                "span#subscription-country-code"
            );

            if (countryCodeSpan) {
                countryCode = countryCodeSpan.getAttribute("value");
            }
        }
        setSubscriptionState(prevState => {
            return {
                ...prevState,
                country: countryCode
            };
        });
    };

    const subscriptionEmailChangeHandler = event => {
        const { target } = event;
        setSubscriptionState(prevState => {
            return { ...prevState, email: target.value };
        });
    };

    const subscriptionFormOnSubmitHandler = e => {
        e.preventDefault();

        let errors = {};

        if (!subscriptionState.name) {
            errors.name = true;
        } else {
            delete errors.name;
        }

        if (!subscriptionState.country) {
            errors.country = true;
        } else {
            delete errors.country;
        }

        if (!subscriptionState.phoneNumber) {
            errors.phoneNumber = true;
        } else {
            delete errors.phoneNumber;
        }

        function validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        if (
            !subscriptionState.email ||
            !validateEmail(subscriptionState.email)
        ) {
            errors.email = true;
        } else {
            delete errors.email;
        }

        setSubscriptionState(prevState => {
            return {
                ...prevState,
                errors
            };
        });

        if (!errors.name && !errors.email && !errors.phoneNumber) {
            ReactGA.event({
                category: "Newsletter",
                action: "Subscription"
            });
            ReactPixel.track("Subscribe", []);

            let subscriptionDetails = {
                searchId: subscriptionState.searchId,
                name: subscriptionState.name,
                country: countries.find(findDetectedCountry).label,
                email: subscriptionState.email,
                phoneNumber: subscriptionState.phoneNumber,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            };

            subscribeNotifySending();

            axios
                .post(
                    "https://calltotravel.com/api/subscribe/new",
                    subscriptionDetails
                )
                .then(() => {
                    subscribeNotifySuccess();
                    setIsSubmitSuccess(true);
                    isSubmitSuccessRef.current = true;
                })
                .catch(() => {
                    subscribeNotifyError();
                });

            setState(prevState => {
                return {
                    ...prevState,
                    subscriptionModalOpen: false
                };
            });
        }
    };

    const editModalShowHandler = () => {
        setState(prevState => {
            return { ...prevState, editModalOpen: true };
        });
    };

    const editModalCloseHandler = () => {
        setState(prevState => {
            return { ...prevState, editModalOpen: false };
        });
    };

    const subscriptionModalCloseHandler = () => {
        setState(prevState => {
            return { ...prevState, subscriptionModalOpen: false };
        });
    };

    const showSubscriptionModal = () => {
        if (!isSubmitSuccessRef.current && !isFormFocusRef.current) {
            setState(prevState => {
                return {
                    ...prevState,
                    subscriptionModalOpen: true
                };
            });
        }
    };

    useEffect(() => {
        setNavExpanded(false);

        ReactPixel.pageView();
        ReactGA.pageview(window.location.pathname + window.location.search);

        setState(prevState => {
            return {
                ...prevState,
                changeOfferId: null,
                isLoading: true
            };
        });

        axios
            .get("https://calltotravel.com/api/searches/" + offerID)
            .then(response => {
                let data = response.data;
                let offerDetails;

                switch (data.page) {
                    case "flights":
                        offerDetails = {
                            page: data.page,
                            tripType: data.trip_type,
                            source: data.source,
                            sourceInput: suggestFullText(data.source),
                            destination: data.destination,
                            destinationInput: suggestFullText(data.destination),
                            startDate: new Date(data.start_date),
                            endDate: new Date(data.end_date),
                            travelers: "" + data.travelers,
                            phoneNumber: data.phone_number,
                            clickUrl: data.click_url
                        };
                        break;

                    case "hotels":
                        offerDetails = {
                            page: data.page,
                            destination: data.destination,
                            destinationInput: suggestFullText(data.destination),
                            startDate: new Date(data.start_date),
                            endDate: new Date(data.end_date),
                            guests: "" + data.guests,
                            rooms: "" + data.rooms,
                            phoneNumber: data.phone_number,
                            clickUrl: data.click_url
                        };
                        break;

                    case "cruises":
                        offerDetails = {
                            page: data.page,
                            destination: data.destination
                                ? data.destination
                                : "",
                            departure: data.departure ? data.departure : "",
                            cruiseLine: data.cruise_line
                                ? data.cruise_line
                                : "",
                            length: data.length ? data.length : "",
                            phoneNumber: data.phone_number,
                            clickUrl: data.click_url
                        };
                        break;

                    case "cars":
                        let startTime = data.start_time.split(":");
                        let startTimeHours = startTime[0];
                        let startTimeMinutes = startTime[1];
                        startTime = new Date();
                        startTime.setHours(startTimeHours);
                        startTime.setMinutes(startTimeMinutes);

                        let endTime = data.end_time.split(":");
                        let endTimeHours = endTime[0];
                        let endTimeMinutes = endTime[1];
                        endTime = new Date();
                        endTime.setHours(endTimeHours);
                        endTime.setMinutes(endTimeMinutes);

                        offerDetails = {
                            page: data.page,
                            differentDropOffLocation: !!data.different_drop_off_location,
                            source: data.source,
                            sourceInput: suggestFullText(data.source),
                            destination: data.different_drop_off_location
                                ? data.destination
                                : null,
                            destinationInput: data.different_drop_off_location
                                ? suggestFullText(data.destination)
                                : null,
                            startDate: new Date(data.start_date),
                            startTime: startTime,
                            endDate: new Date(data.end_date),
                            endTime: endTime,
                            phoneNumber: data.phone_number,
                            clickUrl: data.click_url
                        };
                        break;
                }

                setOfferState(prevState => {
                    return {
                        ...offerDetails,
                        footerCallBoxShown: true,
                        departure: data.departure
                            ? new Date(data.departure)
                            : ""
                    };
                });
                setSearchState(prevState => {
                    return { ...prevState, ...offerDetails };
                });
                setSubscriptionState(prevState => {
                    return {
                        ...prevState,
                        searchId: data.id,
                        country: data.detected_country_code
                    };
                });

                let title;
                switch (data.page) {
                    case "flights":
                        title =
                            " Best Rates For Flights From " +
                            getCityName(offerDetails.source) +
                            " To " +
                            getCityName(offerDetails.destination);
                        title +=
                            offerDetails.tripType === "roundtrip"
                                ? " From " +
                                  getDateString(offerDetails.startDate) +
                                  " To " +
                                  getDateString(offerDetails.endDate)
                                : " On " +
                                  getDateString(offerDetails.startDate);
                        break;

                    case "hotels":
                        title =
                            " Best Rates For Hotels In " +
                            getCityName(offerDetails.destination) +
                            " From " +
                            getDateString(offerDetails.startDate) +
                            " To " +
                            getDateString(offerDetails.endDate);
                        break;
                    case "cruises":
                        title =
                            " Best Rates For Cruises To " +
                            (offerDetails.destination
                                ? offerDetails.destination
                                : "Any Destination") +
                            " In " +
                            (offerDetails.departure
                                ? offerDetails.departure
                                : "Any Time");
                        break;
                    case "cars":
                        title = " Best Rates For Car Rentals";
                        title += offerDetails.differentDropOffLocation
                            ? " From " +
                              getCityName(offerDetails.source) +
                              " To " +
                              getCityName(offerDetails.destination)
                            : " In " + getCityName(offerDetails.source);

                        title +=
                            +" From " +
                            getDateString(offerDetails.startDate) +
                            " To " +
                            getDateString(offerDetails.endDate);
                        break;
                }

                document.title = title;
                setState(prevState => {
                    return {
                        ...prevState,
                        isLoading: false
                    };
                });

                setTimeout(showSubscriptionModal, 45000);
            })
            .catch(error => {
                console.log(error);
            });
    }, [offerID]);

    const suggest = event => {
        setSearchState(prevState => {
            return {
                ...prevState,
                suggestions: [
                    {
                        isLoader: true
                    }
                ]
            };
        });
        let results = [];
        console.log(results);
        axios
            .get("https://calltotravel.com/api/findCity?q=" + event.query)
            .then(response => {
                results = response.data;
                setSearchState(prevState => {
                    return { ...prevState, suggestions: results };
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    const formOnSubmitHandler = e => {
        e.preventDefault();

        var errors = {};

        let searchDetails = null;

        switch (offerState.page) {
            case "flights":
                if (!searchState.source) {
                    errors.source = true;
                } else {
                    delete errors.source;
                }

                if (!searchState.destination) {
                    errors.destination = true;
                } else {
                    delete errors.destination;
                }

                setSearchState(prevState => {
                    return {
                        ...prevState,
                        errors
                    };
                });

                if (!errors.source && !errors.destination) {
                    searchDetails = {
                        page: "flights",
                        tripType: searchState.tripType,
                        source: getIATACode(searchState.source),
                        destination: getIATACode(searchState.destination),
                        startDate: getDateString(searchState.startDate),
                        endDate: getDateString(searchState.endDate),
                        travelers: searchState.travelers
                    };
                }
                break;

            case "hotels":
                if (!searchState.destination) {
                    errors.destination = true;
                } else {
                    delete errors.destination;
                }

                if (!errors.destination) {
                    searchDetails = {
                        page: "hotels",
                        destination: getIATACode(searchState.destination),
                        startDate: getDateString(searchState.startDate),
                        endDate: getDateString(searchState.endDate),
                        guests: searchState.guests,
                        rooms: searchState.rooms
                    };
                }
                break;

            case "cruises":
                searchDetails = {
                    page: "cruises",
                    destination: searchState.destination,
                    departure: searchState.departure,
                    cruiseLine: searchState.cruiseLine,
                    length: searchState.length
                };
                break;

            case "cars":
                if (!searchState.source) {
                    errors.source = true;
                } else {
                    delete errors.source;
                }

                if (
                    searchState.differentDropOffLocation &&
                    !searchState.destination
                ) {
                    errors.destination = true;
                } else {
                    delete errors.destination;
                }

                setSearchState(prevState => {
                    return {
                        ...prevState,
                        errors
                    };
                });

                if (!errors.source && !errors.destination) {
                    searchDetails = {
                        page: "cars",
                        differentDropOffLocation:
                            searchState.differentDropOffLocation,
                        source: getIATACode(searchState.source),
                        destination: searchState.differentDropOffLocation
                            ? getIATACode(searchState.destination)
                            : null,
                        startDate: getDateString(searchState.startDate),
                        startTime: getDateString(searchState.startTime, "H:i"),
                        endDate: getDateString(searchState.endDate),
                        endTime: getDateString(searchState.endTime, "H:i")
                    };
                }
                break;
        }

        if (searchDetails) {
            ReactGA.event({
                category: "Click",
                action: "Front"
            });
            ReactPixel.track("Search", []);
            setState(prevState => {
                return {
                    ...prevState,
                    editModalOpen: false,
                    isLoading: true
                };
            });
            axios
                .post(
                    "https://calltotravel.com/api/searches/add",
                    searchDetails
                )
                .then(response => {
                    setState(prevState => {
                        return { ...prevState, changeOfferId: response.data };
                    });
                });
        }
    };

    const clickToCallHandler = e => {
        ReactGA.event({
            category: "Phone",
            action: "Call"
        });
        ReactPixel.track("Call", []);

        axios.get(
            "https://calltotravel.com/api/searches/" + offerID + "/makeCall"
        );

        if (offerState.clickUrl) {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                    window.location.href = "tel:" + offerState.phoneNumber;
                }
            };
            xhr.open("GET", offerState.clickUrl, true);
            xhr.withCredentials = true;
            xhr.send();
        } else {
            window.location.href = "tel:" + offerState.phoneNumber;
        }
    };

    const classes = useStyles();

    const countries = [
        { code: "AD", label: "Andorra", phone: "376" },
        { code: "AE", label: "United Arab Emirates", phone: "971" },
        { code: "AF", label: "Afghanistan", phone: "93" },
        { code: "AG", label: "Antigua and Barbuda", phone: "1-268" },
        { code: "AI", label: "Anguilla", phone: "1-264" },
        { code: "AL", label: "Albania", phone: "355" },
        { code: "AM", label: "Armenia", phone: "374" },
        { code: "AO", label: "Angola", phone: "244" },
        { code: "AQ", label: "Antarctica", phone: "672" },
        { code: "AR", label: "Argentina", phone: "54" },
        { code: "AS", label: "American Samoa", phone: "1-684" },
        { code: "AT", label: "Austria", phone: "43" },
        { code: "AU", label: "Australia", phone: "61", suggested: true },
        { code: "AW", label: "Aruba", phone: "297" },
        { code: "AX", label: "Alland Islands", phone: "358" },
        { code: "AZ", label: "Azerbaijan", phone: "994" },
        { code: "BA", label: "Bosnia and Herzegovina", phone: "387" },
        { code: "BB", label: "Barbados", phone: "1-246" },
        { code: "BD", label: "Bangladesh", phone: "880" },
        { code: "BE", label: "Belgium", phone: "32" },
        { code: "BF", label: "Burkina Faso", phone: "226" },
        { code: "BG", label: "Bulgaria", phone: "359" },
        { code: "BH", label: "Bahrain", phone: "973" },
        { code: "BI", label: "Burundi", phone: "257" },
        { code: "BJ", label: "Benin", phone: "229" },
        { code: "BL", label: "Saint Barthelemy", phone: "590" },
        { code: "BM", label: "Bermuda", phone: "1-441" },
        { code: "BN", label: "Brunei Darussalam", phone: "673" },
        { code: "BO", label: "Bolivia", phone: "591" },
        { code: "BR", label: "Brazil", phone: "55" },
        { code: "BS", label: "Bahamas", phone: "1-242" },
        { code: "BT", label: "Bhutan", phone: "975" },
        { code: "BV", label: "Bouvet Island", phone: "47" },
        { code: "BW", label: "Botswana", phone: "267" },
        { code: "BY", label: "Belarus", phone: "375" },
        { code: "BZ", label: "Belize", phone: "501" },
        { code: "CA", label: "Canada", phone: "1", suggested: true },
        { code: "CC", label: "Cocos (Keeling) Islands", phone: "61" },
        {
            code: "CD",
            label: "Congo, Democratic Republic of the",
            phone: "243"
        },
        { code: "CF", label: "Central African Republic", phone: "236" },
        { code: "CG", label: "Congo, Republic of the", phone: "242" },
        { code: "CH", label: "Switzerland", phone: "41" },
        { code: "CI", label: "Cote d'Ivoire", phone: "225" },
        { code: "CK", label: "Cook Islands", phone: "682" },
        { code: "CL", label: "Chile", phone: "56" },
        { code: "CM", label: "Cameroon", phone: "237" },
        { code: "CN", label: "China", phone: "86" },
        { code: "CO", label: "Colombia", phone: "57" },
        { code: "CR", label: "Costa Rica", phone: "506" },
        { code: "CU", label: "Cuba", phone: "53" },
        { code: "CV", label: "Cape Verde", phone: "238" },
        { code: "CW", label: "Curacao", phone: "599" },
        { code: "CX", label: "Christmas Island", phone: "61" },
        { code: "CY", label: "Cyprus", phone: "357" },
        { code: "CZ", label: "Czech Republic", phone: "420" },
        { code: "DE", label: "Germany", phone: "49", suggested: true },
        { code: "DJ", label: "Djibouti", phone: "253" },
        { code: "DK", label: "Denmark", phone: "45" },
        { code: "DM", label: "Dominica", phone: "1-767" },
        { code: "DO", label: "Dominican Republic", phone: "1-809" },
        { code: "DZ", label: "Algeria", phone: "213" },
        { code: "EC", label: "Ecuador", phone: "593" },
        { code: "EE", label: "Estonia", phone: "372" },
        { code: "EG", label: "Egypt", phone: "20" },
        { code: "EH", label: "Western Sahara", phone: "212" },
        { code: "ER", label: "Eritrea", phone: "291" },
        { code: "ES", label: "Spain", phone: "34" },
        { code: "ET", label: "Ethiopia", phone: "251" },
        { code: "FI", label: "Finland", phone: "358" },
        { code: "FJ", label: "Fiji", phone: "679" },
        { code: "FK", label: "Falkland Islands (Malvinas)", phone: "500" },
        { code: "FM", label: "Micronesia, Federated States of", phone: "691" },
        { code: "FO", label: "Faroe Islands", phone: "298" },
        { code: "FR", label: "France", phone: "33", suggested: true },
        { code: "GA", label: "Gabon", phone: "241" },
        { code: "GB", label: "United Kingdom", phone: "44" },
        { code: "GD", label: "Grenada", phone: "1-473" },
        { code: "GE", label: "Georgia", phone: "995" },
        { code: "GF", label: "French Guiana", phone: "594" },
        { code: "GG", label: "Guernsey", phone: "44" },
        { code: "GH", label: "Ghana", phone: "233" },
        { code: "GI", label: "Gibraltar", phone: "350" },
        { code: "GL", label: "Greenland", phone: "299" },
        { code: "GM", label: "Gambia", phone: "220" },
        { code: "GN", label: "Guinea", phone: "224" },
        { code: "GP", label: "Guadeloupe", phone: "590" },
        { code: "GQ", label: "Equatorial Guinea", phone: "240" },
        { code: "GR", label: "Greece", phone: "30" },
        {
            code: "GS",
            label: "South Georgia and the South Sandwich Islands",
            phone: "500"
        },
        { code: "GT", label: "Guatemala", phone: "502" },
        { code: "GU", label: "Guam", phone: "1-671" },
        { code: "GW", label: "Guinea-Bissau", phone: "245" },
        { code: "GY", label: "Guyana", phone: "592" },
        { code: "HK", label: "Hong Kong", phone: "852" },
        {
            code: "HM",
            label: "Heard Island and McDonald Islands",
            phone: "672"
        },
        { code: "HN", label: "Honduras", phone: "504" },
        { code: "HR", label: "Croatia", phone: "385" },
        { code: "HT", label: "Haiti", phone: "509" },
        { code: "HU", label: "Hungary", phone: "36" },
        { code: "ID", label: "Indonesia", phone: "62" },
        { code: "IE", label: "Ireland", phone: "353" },
        { code: "IL", label: "Israel", phone: "972" },
        { code: "IM", label: "Isle of Man", phone: "44" },
        { code: "IN", label: "India", phone: "91" },
        { code: "IO", label: "British Indian Ocean Territory", phone: "246" },
        { code: "IQ", label: "Iraq", phone: "964" },
        { code: "IR", label: "Iran, Islamic Republic of", phone: "98" },
        { code: "IS", label: "Iceland", phone: "354" },
        { code: "IT", label: "Italy", phone: "39" },
        { code: "JE", label: "Jersey", phone: "44" },
        { code: "JM", label: "Jamaica", phone: "1-876" },
        { code: "JO", label: "Jordan", phone: "962" },
        { code: "JP", label: "Japan", phone: "81", suggested: true },
        { code: "KE", label: "Kenya", phone: "254" },
        { code: "KG", label: "Kyrgyzstan", phone: "996" },
        { code: "KH", label: "Cambodia", phone: "855" },
        { code: "KI", label: "Kiribati", phone: "686" },
        { code: "KM", label: "Comoros", phone: "269" },
        { code: "KN", label: "Saint Kitts and Nevis", phone: "1-869" },
        {
            code: "KP",
            label: "Korea, Democratic People's Republic of",
            phone: "850"
        },
        { code: "KR", label: "Korea, Republic of", phone: "82" },
        { code: "KW", label: "Kuwait", phone: "965" },
        { code: "KY", label: "Cayman Islands", phone: "1-345" },
        { code: "KZ", label: "Kazakhstan", phone: "7" },
        { code: "LA", label: "Lao People's Democratic Republic", phone: "856" },
        { code: "LB", label: "Lebanon", phone: "961" },
        { code: "LC", label: "Saint Lucia", phone: "1-758" },
        { code: "LI", label: "Liechtenstein", phone: "423" },
        { code: "LK", label: "Sri Lanka", phone: "94" },
        { code: "LR", label: "Liberia", phone: "231" },
        { code: "LS", label: "Lesotho", phone: "266" },
        { code: "LT", label: "Lithuania", phone: "370" },
        { code: "LU", label: "Luxembourg", phone: "352" },
        { code: "LV", label: "Latvia", phone: "371" },
        { code: "LY", label: "Libya", phone: "218" },
        { code: "MA", label: "Morocco", phone: "212" },
        { code: "MC", label: "Monaco", phone: "377" },
        { code: "MD", label: "Moldova, Republic of", phone: "373" },
        { code: "ME", label: "Montenegro", phone: "382" },
        { code: "MF", label: "Saint Martin (French part)", phone: "590" },
        { code: "MG", label: "Madagascar", phone: "261" },
        { code: "MH", label: "Marshall Islands", phone: "692" },
        {
            code: "MK",
            label: "Macedonia, the Former Yugoslav Republic of",
            phone: "389"
        },
        { code: "ML", label: "Mali", phone: "223" },
        { code: "MM", label: "Myanmar", phone: "95" },
        { code: "MN", label: "Mongolia", phone: "976" },
        { code: "MO", label: "Macao", phone: "853" },
        { code: "MP", label: "Northern Mariana Islands", phone: "1-670" },
        { code: "MQ", label: "Martinique", phone: "596" },
        { code: "MR", label: "Mauritania", phone: "222" },
        { code: "MS", label: "Montserrat", phone: "1-664" },
        { code: "MT", label: "Malta", phone: "356" },
        { code: "MU", label: "Mauritius", phone: "230" },
        { code: "MV", label: "Maldives", phone: "960" },
        { code: "MW", label: "Malawi", phone: "265" },
        { code: "MX", label: "Mexico", phone: "52" },
        { code: "MY", label: "Malaysia", phone: "60" },
        { code: "MZ", label: "Mozambique", phone: "258" },
        { code: "NA", label: "Namibia", phone: "264" },
        { code: "NC", label: "New Caledonia", phone: "687" },
        { code: "NE", label: "Niger", phone: "227" },
        { code: "NF", label: "Norfolk Island", phone: "672" },
        { code: "NG", label: "Nigeria", phone: "234" },
        { code: "NI", label: "Nicaragua", phone: "505" },
        { code: "NL", label: "Netherlands", phone: "31" },
        { code: "NO", label: "Norway", phone: "47" },
        { code: "NP", label: "Nepal", phone: "977" },
        { code: "NR", label: "Nauru", phone: "674" },
        { code: "NU", label: "Niue", phone: "683" },
        { code: "NZ", label: "New Zealand", phone: "64" },
        { code: "OM", label: "Oman", phone: "968" },
        { code: "PA", label: "Panama", phone: "507" },
        { code: "PE", label: "Peru", phone: "51" },
        { code: "PF", label: "French Polynesia", phone: "689" },
        { code: "PG", label: "Papua New Guinea", phone: "675" },
        { code: "PH", label: "Philippines", phone: "63" },
        { code: "PK", label: "Pakistan", phone: "92" },
        { code: "PL", label: "Poland", phone: "48" },
        { code: "PM", label: "Saint Pierre and Miquelon", phone: "508" },
        { code: "PN", label: "Pitcairn", phone: "870" },
        { code: "PR", label: "Puerto Rico", phone: "1" },
        { code: "PS", label: "Palestine, State of", phone: "970" },
        { code: "PT", label: "Portugal", phone: "351" },
        { code: "PW", label: "Palau", phone: "680" },
        { code: "PY", label: "Paraguay", phone: "595" },
        { code: "QA", label: "Qatar", phone: "974" },
        { code: "RE", label: "Reunion", phone: "262" },
        { code: "RO", label: "Romania", phone: "40" },
        { code: "RS", label: "Serbia", phone: "381" },
        { code: "RU", label: "Russian Federation", phone: "7" },
        { code: "RW", label: "Rwanda", phone: "250" },
        { code: "SA", label: "Saudi Arabia", phone: "966" },
        { code: "SB", label: "Solomon Islands", phone: "677" },
        { code: "SC", label: "Seychelles", phone: "248" },
        { code: "SD", label: "Sudan", phone: "249" },
        { code: "SE", label: "Sweden", phone: "46" },
        { code: "SG", label: "Singapore", phone: "65" },
        { code: "SH", label: "Saint Helena", phone: "290" },
        { code: "SI", label: "Slovenia", phone: "386" },
        { code: "SJ", label: "Svalbard and Jan Mayen", phone: "47" },
        { code: "SK", label: "Slovakia", phone: "421" },
        { code: "SL", label: "Sierra Leone", phone: "232" },
        { code: "SM", label: "San Marino", phone: "378" },
        { code: "SN", label: "Senegal", phone: "221" },
        { code: "SO", label: "Somalia", phone: "252" },
        { code: "SR", label: "Suriname", phone: "597" },
        { code: "SS", label: "South Sudan", phone: "211" },
        { code: "ST", label: "Sao Tome and Principe", phone: "239" },
        { code: "SV", label: "El Salvador", phone: "503" },
        { code: "SX", label: "Sint Maarten (Dutch part)", phone: "1-721" },
        { code: "SY", label: "Syrian Arab Republic", phone: "963" },
        { code: "SZ", label: "Swaziland", phone: "268" },
        { code: "TC", label: "Turks and Caicos Islands", phone: "1-649" },
        { code: "TD", label: "Chad", phone: "235" },
        { code: "TF", label: "French Southern Territories", phone: "262" },
        { code: "TG", label: "Togo", phone: "228" },
        { code: "TH", label: "Thailand", phone: "66" },
        { code: "TJ", label: "Tajikistan", phone: "992" },
        { code: "TK", label: "Tokelau", phone: "690" },
        { code: "TL", label: "Timor-Leste", phone: "670" },
        { code: "TM", label: "Turkmenistan", phone: "993" },
        { code: "TN", label: "Tunisia", phone: "216" },
        { code: "TO", label: "Tonga", phone: "676" },
        { code: "TR", label: "Turkey", phone: "90" },
        { code: "TT", label: "Trinidad and Tobago", phone: "1-868" },
        { code: "TV", label: "Tuvalu", phone: "688" },
        { code: "TW", label: "Taiwan, Province of China", phone: "886" },
        { code: "TZ", label: "United Republic of Tanzania", phone: "255" },
        { code: "UA", label: "Ukraine", phone: "380" },
        { code: "UG", label: "Uganda", phone: "256" },
        { code: "US", label: "United States", phone: "1", suggested: true },
        { code: "UY", label: "Uruguay", phone: "598" },
        { code: "UZ", label: "Uzbekistan", phone: "998" },
        { code: "VA", label: "Holy See (Vatican City State)", phone: "379" },
        {
            code: "VC",
            label: "Saint Vincent and the Grenadines",
            phone: "1-784"
        },
        { code: "VE", label: "Venezuela", phone: "58" },
        { code: "VG", label: "British Virgin Islands", phone: "1-284" },
        { code: "VI", label: "US Virgin Islands", phone: "1-340" },
        { code: "VN", label: "Vietnam", phone: "84" },
        { code: "VU", label: "Vanuatu", phone: "678" },
        { code: "WF", label: "Wallis and Futuna", phone: "681" },
        { code: "WS", label: "Samoa", phone: "685" },
        { code: "XK", label: "Kosovo", phone: "383" },
        { code: "YE", label: "Yemen", phone: "967" },
        { code: "YT", label: "Mayotte", phone: "262" },
        { code: "ZA", label: "South Africa", phone: "27" },
        { code: "ZM", label: "Zambia", phone: "260" },
        { code: "ZW", label: "Zimbabwe", phone: "263" }
    ];

    const findDetectedCountry = country => {
        return country.code === subscriptionState.country;
    };

    return state.changeOfferId ? (
        <Redirect to={"offer?id=" + state.changeOfferId} push={true} />
    ) : state.isLoading ? (
        <div className={"loader"}>
            <div className="lds-ring">
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    ) : offerState.page === "flights" ? (
        <OfferFlights
            classes={classes}
            clickToCallHandler={clickToCallHandler}
            countries={countries}
            countryToFlag={countryToFlag}
            editModalCloseHandler={editModalCloseHandler}
            editModalShowHandler={editModalShowHandler}
            findDetectedCountry={findDetectedCountry}
            formOnSubmitHandler={formOnSubmitHandler}
            getCityName={getCityName}
            getDateString={getDateString}
            offerState={offerState}
            searchState={searchState}
            setOfferState={setOfferState}
            setSearchState={setSearchState}
            setState={setState}
            state={state}
            subscriptionCountryChangeHandler={subscriptionCountryChangeHandler}
            subscriptionEmailChangeHandler={subscriptionEmailChangeHandler}
            subscriptionPhoneChangeHandler={subscriptionPhoneChangeHandler}
            subscriptionFormOnSubmitHandler={subscriptionFormOnSubmitHandler}
            subscriptionModalCloseHandler={subscriptionModalCloseHandler}
            subscriptionNameChangeHandler={subscriptionNameChangeHandler}
            subscriptionState={subscriptionState}
            suggest={suggest}
            suggestFullText={suggestFullText}
            suggestTemplate={suggestTemplate}
            isSubmitSuccess={isSubmitSuccess}
            setIsSubmitSuccess={setIsSubmitSuccess}
            isSubmitSuccess={isSubmitSuccess}
            setIsFormFocus={setIsFormFocus}
        />
    ) : offerState.page === "hotels" ? (
        <OfferHotels
            classes={classes}
            clickToCallHandler={clickToCallHandler}
            countries={countries}
            countryToFlag={countryToFlag}
            editModalCloseHandler={editModalCloseHandler}
            editModalShowHandler={editModalShowHandler}
            findDetectedCountry={findDetectedCountry}
            formOnSubmitHandler={formOnSubmitHandler}
            getCityName={getCityName}
            getDateString={getDateString}
            offerState={offerState}
            searchState={searchState}
            setOfferState={setOfferState}
            setSearchState={setSearchState}
            setState={setState}
            state={state}
            subscriptionCountryChangeHandler={subscriptionCountryChangeHandler}
            subscriptionEmailChangeHandler={subscriptionEmailChangeHandler}
            subscriptionPhoneChangeHandler={subscriptionPhoneChangeHandler}
            subscriptionFormOnSubmitHandler={subscriptionFormOnSubmitHandler}
            subscriptionModalCloseHandler={subscriptionModalCloseHandler}
            subscriptionNameChangeHandler={subscriptionNameChangeHandler}
            subscriptionState={subscriptionState}
            suggest={suggest}
            suggestFullText={suggest}
            suggestTemplate={suggestTemplate}
            setIsSubmitSuccess={setIsSubmitSuccess}
            isSubmitSuccess={isSubmitSuccess}
            setIsFormFocus={setIsFormFocus}
        />
    ) : offerState.page === "cars" ? (
        <OfferCars
            classes={classes}
            clickToCallHandler={clickToCallHandler}
            countries={countries}
            countryToFlag={countryToFlag}
            editModalCloseHandler={editModalCloseHandler}
            editModalShowHandler={editModalShowHandler}
            findDetectedCountry={findDetectedCountry}
            formOnSubmitHandler={formOnSubmitHandler}
            getCityName={getCityName}
            getDateString={getDateString}
            offerState={offerState}
            searchState={searchState}
            setOfferState={setOfferState}
            setSearchState={setSearchState}
            setState={setState}
            state={state}
            subscriptionCountryChangeHandler={subscriptionCountryChangeHandler}
            subscriptionEmailChangeHandler={subscriptionEmailChangeHandler}
            subscriptionPhoneChangeHandler={subscriptionPhoneChangeHandler}
            subscriptionFormOnSubmitHandler={subscriptionFormOnSubmitHandler}
            subscriptionModalCloseHandler={subscriptionModalCloseHandler}
            subscriptionNameChangeHandler={subscriptionNameChangeHandler}
            subscriptionState={subscriptionState}
            suggest={suggest}
            suggestFullText={suggestFullText}
            suggestTemplate={suggestTemplate}
            setIsSubmitSuccess={setIsSubmitSuccess}
            isSubmitSuccess={isSubmitSuccess}
            setIsFormFocus={setIsFormFocus}
        />
    ) : offerState.page === "cruises" ? (
        <OfferCruises
            classes={classes}
            clickToCallHandler={clickToCallHandler}
            countries={countries}
            countryToFlag={countryToFlag}
            editModalCloseHandler={editModalCloseHandler}
            editModalShowHandler={editModalShowHandler}
            findDetectedCountry={findDetectedCountry}
            formOnSubmitHandler={formOnSubmitHandler}
            getCityName={getCityName}
            getDateString={getDateString}
            offerState={offerState}
            searchState={searchState}
            setOfferState={setOfferState}
            setSearchState={setSearchState}
            setState={setState}
            state={state}
            subscriptionCountryChangeHandler={subscriptionCountryChangeHandler}
            subscriptionEmailChangeHandler={subscriptionEmailChangeHandler}
            subscriptionFormOnSubmitHandler={subscriptionFormOnSubmitHandler}
            subscriptionModalCloseHandler={subscriptionModalCloseHandler}
            subscriptionNameChangeHandler={subscriptionNameChangeHandler}
            subscriptionPhoneChangeHandler={subscriptionPhoneChangeHandler}
            subscriptionState={subscriptionState}
            suggest={suggest}
            suggestFullText={suggestFullText}
            suggestTemplate={suggestTemplate}
            setIsSubmitSuccess={setIsSubmitSuccess}
            isSubmitSuccess={isSubmitSuccess}
            setIsFormFocus={setIsFormFocus}
        />
    ) : null;
}

export default Offer2;
