import React, { useReducer, useEffect, lazy, Suspense, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import TopHeader from "./components/TopHeader";
import Main from "./components/Main";
import axios from "axios";
import ReactGA from "react-ga";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
//const FlightsPage = lazy(() => import("./components/FlightsPage"));

import FlightsPage from "./components/FlightsPage";
import HotelsPage from "./components/HotelsPage";
import CruisesPage from "./components/CruisesPage";
import CarsPage from "./components/CarsPage";
import Offer2 from "./components/Offer2";

import ReactPixel from "react-facebook-pixel";

import { createBrowserHistory } from "history";
import PrivacyPolicy from "./components/privacy-policy";
import TermsOfService from "./components/terms-of-service";
import ContactUs from "./components/contact-us";

ReactGA.initialize("UA-167451019-1");

ReactPixel.init("557824411586333");

const history = createBrowserHistory();

export const AppContext = React.createContext(null);

const initialState = {
    selectedPage: "Flights"
};

const routes = [
    {
        path: ["/search/flights/title/:title", "/search/flights/"],
        component: FlightsPage
    },
    {
        path: ["/search/hotels/title/:title", "/search/hotels/"],
        component: HotelsPage
    },
    {
        path: ["/search/cruises/title/:title", "/search/cruises/"],
        component: CruisesPage
    },
    {
        path: ["/search/cars/title/:title", "/search/cars/"],
        component: CarsPage
    },
    {
        path: "/Offer",
        component: Offer2
    },
    {
        path: "/privacypolicy",
        component: PrivacyPolicy
    },
    {
        path: "/termsofservice",
        component: TermsOfService
    },
    {
        path: "/contactus",
        component: ContactUs
    },
    {
        path: "/",
        component: Main
    }
];

function setToCache(key, value) {
    var currentDate = new Date();
    value.timestamp = currentDate.getTime();
    localStorage.setItem(key, JSON.stringify(value));
}

function getFromCache(key) {
    var cachedResult = localStorage.getItem(key);
    var currentDate = new Date();
    var currentTimestamp = currentDate.getTime();

    if (cachedResult) {
        cachedResult = JSON.parse(cachedResult);
        // Valid only for 2 hours
        if (currentTimestamp - cachedResult.timestamp <= 7200000) {
            return cachedResult;
        } else {
            localStorage.removeItem(key);
            return null;
        }
    }
    return null;
}

export const LocationContext = React.createContext("a");

export const SetNavExpandedContext = React.createContext(() => {});

history.listen(location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
    ReactPixel.pageView();
});

function App() {
    //const [appState, appStateDispatcher] = useReducer(reducer, initialState);

    const [appState, setAppState] = useState(initialState);
    const [navExpanded, setNavExpanded] = useState(false);

    /*useEffect(() => {
        // Search Local storage
        var cachedResult = getFromCache("cached_location");

        if (cachedResult) {
            setAppState(prevState => {
                return { ...prevState, location: cachedResult };
            });
        }

        // var url = 'http://api.ipstack.com/check?access_key=32e15c43f1a00cce2014abd18c1f7b24';

        var result = null;
        axios.get("http://ip-api.com/json").then(response => {
            let data = response.data;
            let searchCriteria = null;
            if (data.city) {
                searchCriteria = data.city;
            } else if (data.country) {
                searchCriteria = data.country;
            } else if (data.countryCode) {
                searchCriteria = data.countryCode;
            }
            if (searchCriteria) {
                axios
                    .get(
                        "https://getmyfare.com/api/findCity?q=" + searchCriteria
                    )
                    .then(response => {
                        let data = response.data;
                        if (data[0]) {
                            setAppState(prevState => {
                                return { ...prevState, location: data[0] };
                            });
                            setToCache("cached_location", data[0]);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        });
    }, []);*/

    return (
        <LocationContext.Provider value={appState.location}>
            <SetNavExpandedContext.Provider value={setNavExpanded}>
                <Router history={history}>
                    <TopHeader
                        navExpanded={navExpanded}
                        setNavExpanded={setNavExpanded}
                    />
                    <Switch>
                        {routes.map((route, i) => (
                            <Route key={i} {...route} />
                        ))}
                    </Switch>
                </Router>
            </SetNavExpandedContext.Provider>
        </LocationContext.Provider>
    );
}

function WaitingComponent(Component) {
    return props => (
        <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
        </Suspense>
    );
}

export default App;
