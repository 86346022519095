import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { RadioButton } from "primereact/radiobutton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "./FormGroup";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { FaCity, FaSpinner, MdLocalAirport } from "react-icons/all";

import {
    BrowserRouter as Router,
    Redirect,
    useParams,
    useRouteMatch
} from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import Footer from "./footer";

const addDaysToDate = (date, days) => {
    let aux = new Date(date);
    return new Date(aux.setTime(aux.getTime() + days * 24 * 60 * 60 * 1000));
};

const todayDate = new Date();
const guestsOptions = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" }
];

const roomsOptions = [
    { label: "1", value: "1" },
    { label: "2", value: "2" }
];

const initialState = {
    checkInMinDate: addDaysToDate(todayDate, 7),
    checkInDate: addDaysToDate(todayDate, 7),
    checkOutDate: addDaysToDate(todayDate, 10),

    guests: guestsOptions[0].value,

    rooms: roomsOptions[0].value
};

const getDateString = (date, format = "mm/dd/yyyy") => {
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ];

    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    let H = date.getHours();
    let i = date.getMinutes();
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    let M = months[mm - 1];
    let D = days[date.getDay()];
    if (H < 10) {
        H = "0" + H;
    }

    if (i < 10) {
        i = "0" + i;
    }

    if (dd < 10) {
        dd = "0" + dd;
    }

    if (mm < 10) {
        mm = "0" + mm;
    }
    switch (format) {
        case "mm/dd/yyyy":
            return mm + "/" + dd + "/" + yyyy;
        case "dd/mm/yyyy":
            return dd + "/" + mm + "/" + yyyy;
        case "D, M dd":
            return D + ", " + M + " " + dd;
        case "H:i":
            return H + ":" + i;
    }
};

const suggestTemplate = sug => {
    if (sug.isLoader)
        return (
            <div className={"text-center"}>
                <FaSpinner className={"icon-spin"} />
            </div>
        );
    return (
        <>
            {sug.iata_city ? (
                <>
                    <FaCity className={"mr-1"} /> {sug.iata_city}{" "}
                </>
            ) : (
                <>
                    <MdLocalAirport className={"mr-1"} /> {sug.iata_code}{" "}
                </>
            )}{" "}
            - {sug.city_name ? sug.city_name : sug.airport_name},{" "}
            {sug.country_code}
        </>
    );
};

const getIATACode = sug => {
    return sug.iata_city ? sug.iata_city : sug.iata_code;
};

const suggestFullText = sug => {
    return (
        "" +
        (sug.iata_city ? sug.iata_city : sug.iata_code) +
        " - " +
        (sug.city_name ? sug.city_name : sug.airport_name) +
        ", " +
        sug.country_code
    );
};

function HotelsPage(props) {
    const [state, setState] = useState(initialState);

    const suggest = event => {
        setState(prevState => {
            return {
                ...prevState,
                suggestions: [
                    {
                        isLoader: true
                    }
                ]
            };
        });
        let results = [];
        console.log(results);
        axios
            .get("https://getmyfare.com/api/findCity?q=" + event.query)
            .then(response => {
                results = response.data;
                setState(prevState => {
                    return { ...prevState, suggestions: results };
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    const destinationOnChangeHandler = e => {
        setState(prevState => {
            return { ...prevState, destinationInput: e.value };
        });
    };

    const destinationOnSelectHandler = e => {
        setState(prevState => {
            return {
                ...prevState,
                destinationInput: suggestFullText(e.value),
                destination: e.value
            };
        });
    };

    let { title } = useParams();

    useEffect(() => {
        document.title = "Call to Find Cheap Hotels to Book";
    }, []);

    const formSubmitHandler = e => {
        e.preventDefault();

        var errors = {};

        if (!state.destination) {
            errors.destination = true;
        } else {
            delete errors.destination;
        }

        setState(prevState => {
            return {
                ...prevState,
                errors
            };
        });

        if (!errors.source && !errors.destination) {
            ReactGA.event({
                category: "Front",
                action: "Click"
            });
            ReactPixel.track("Search", []);

            setState(prevState => {
                return {
                    ...prevState,
                    isLoading: true
                };
            });

            let searchDetails = {
                page: "hotels",
                destination: getIATACode(state.destination),
                startDate: getDateString(state.checkInDate),
                endDate: getDateString(state.checkOutDate),
                guests: state.guests,
                rooms: state.rooms
            };

            console.log(searchDetails);

            const customHeaders = {
                "content-type": "application/json"
            };

            axios
                .post(
                    "https://calltotravel.com/api/searches/add",
                    searchDetails
                )
                .then(response => {
                    setState(prevState => {
                        return { ...prevState, changeOfferId: response.data };
                    });
                });
        }
    };

    return state.changeOfferId ? (
        <Redirect to={"/offer?id=" + state.changeOfferId} push={true} />
    ) : (
        <>
            <div className={"bg bg-hotels py-5 text-white"}>
                <div className={"container"}>
                    <div className={"main-box p-3"}>
                        <h1 className={"shadowed"}>
                            <b>{title ? title : "Search Hotels"}</b>
                        </h1>

                        <form onSubmit={formSubmitHandler}>
                            <Row className={"mt-4"}>
                                <Col sm={12}>
                                    <FormGroup title={"Destination"}>
                                        <AutoComplete
                                            className={"w-100"}
                                            inputClassName={"w-100"}
                                            placeholder={"City or airport code"}
                                            value={state.destinationInput}
                                            itemTemplate={suggestTemplate}
                                            onChange={destinationOnChangeHandler}
                                            onSelect={destinationOnSelectHandler}
                                            suggestions={state.suggestions}
                                            completeMethod={suggest}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup title={"Check In"}>
                                        <Calendar
                                            className={"w-100"}
                                            showIcon={true}
                                            minDate={state.checkInMinDate}
                                            value={state.checkInDate}
                                            readOnlyInput={true}
                                            onChange={e => {
                                                setState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        checkInDate: e.value
                                                    };
                                                });

                                                if (e.value > state.checkOutDate) {
                                                    setState(prevState => {
                                                        return {
                                                            ...prevState,
                                                            checkOutDate: e.value
                                                        };
                                                    });
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup title={"Check Out"}>
                                        <Calendar
                                            className={"w-100"}
                                            showIcon={true}
                                            minDate={state.checkInDate}
                                            value={state.checkOutDate}
                                            onChange={e =>
                                                setState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        checkOutDate: e.value
                                                    };
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <FormGroup title={"Guests"}>
                                        <Dropdown
                                            className={"w-100"}
                                            inputClassName={"w-100"}
                                            value={state.guests}
                                            options={guestsOptions}
                                            onChange={e => {
                                                setState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        guests: e.value
                                                    };
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <FormGroup title={"Rooms"}>
                                        <Dropdown
                                            className={"w-100"}
                                            inputClassName={"w-100"}
                                            value={state.rooms}
                                            options={roomsOptions}
                                            onChange={e => {
                                                setState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        rooms: e.value
                                                    };
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <div className={"my-3 d-flex align-items-center"}>
                                <div className={"upTo70"} />
                                <Button
                                    label="Search"
                                    icon="pi pi-search"
                                    className="p-button-raised p-button-rounded searchBtn"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default HotelsPage;
