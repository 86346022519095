import React, { useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import { Spinner } from "primereact/spinner";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FaCity, FaSpinner, MdLocalAirport } from "react-icons/all";
import axios from "axios";
import FormGroup from "./FormGroup";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { Redirect } from "react-router-dom";

const addDaysToDate = (date, days) => {
    let aux = new Date(date);
    return new Date(aux.setTime(aux.getTime() + days * 24 * 60 * 60 * 1000));
};

const todayDate = new Date();
const calendarTime = new Date();
calendarTime.setHours(12, 0);
const initialState = {
    pickUpMinDate: todayDate,
    pickUpDate: addDaysToDate(todayDate, 7),
    pickUpTime: calendarTime,
    dropOffDate: addDaysToDate(todayDate, 10),
    dropOffTime: calendarTime,

    differentDropOffLocation: false
};

const getDateString = (date, format = "mm/dd/yyyy") => {
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ];

    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    let H = date.getHours();
    let i = date.getMinutes();
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    let M = months[mm - 1];
    let D = days[date.getDay()];
    if (H < 10) {
        H = "0" + H;
    }

    if (i < 10) {
        i = "0" + i;
    }

    if (dd < 10) {
        dd = "0" + dd;
    }

    if (mm < 10) {
        mm = "0" + mm;
    }
    switch (format) {
        case "mm/dd/yyyy":
            return mm + "/" + dd + "/" + yyyy;
        case "dd/mm/yyyy":
            return dd + "/" + mm + "/" + yyyy;
        case "D, M dd":
            return D + ", " + M + " " + dd;
        case "H:i":
            return H + ":" + i;
    }
};

const getIATACode = sug => {
    return sug.iata_city ? sug.iata_city : sug.iata_code;
};

const suggestTemplate = sug => {
    if (sug.isLoader)
        return (
            <div className={"text-center"}>
                <FaSpinner className={"icon-spin"} />
            </div>
        );
    return (
        <>
            {sug.iata_city ? (
                <>
                    <FaCity className={"mr-1"} /> {sug.iata_city}{" "}
                </>
            ) : (
                <>
                    <MdLocalAirport className={"mr-1"} /> {sug.iata_code}{" "}
                </>
            )}{" "}
            - {sug.city_name ? sug.city_name : sug.airport_name},{" "}
            {sug.country_code}
        </>
    );
};

const suggestFullText = sug => {
    return (
        "" +
        (sug.iata_city ? sug.iata_city : sug.iata_code) +
        " - " +
        (sug.city_name ? sug.city_name : sug.airport_name) +
        ", " +
        sug.country_code
    );
};

function HomeCars(props) {
    const [state, setState] = useState(initialState);

    const suggest = event => {
        setState(prevState => {
            return {
                ...prevState,
                suggestions: [
                    {
                        isLoader: true
                    }
                ]
            };
        });
        let results = [];
        console.log(results);
        axios
            .get("https://getmyfare.com/api/findCity?q=" + event.query)
            .then(response => {
                console.log(response);
                results = response.data;
                setState(prevState => {
                    return { ...prevState, suggestions: results };
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    const pickUpOnChangeHandler = e => {
        setState(prevState => {
            return { ...prevState, pickUpLocationInput: e.value };
        });
    };

    const pickUpOnSelectHandler = e => {
        setState(prevState => {
            return {
                ...prevState,
                pickUpLocationInput: suggestFullText(e.value),
                pickUpLocation: e.value
            };
        });
    };

    const dropOffOnChangeHandler = e => {
        setState(prevState => {
            return { ...prevState, dropOffLocationInput: e.value };
        });
    };

    const dropOffOnSelectHandler = e => {
        setState(prevState => {
            return {
                ...prevState,
                dropOffLocationInput: suggestFullText(e.value),
                dropOffLocation: e.value
            };
        });
    };

    const formSubmitHandler = e => {
        e.preventDefault();

        var errors = {};

        if (!state.pickUpLocation) {
            errors.pickUpLocation = true;
        } else {
            delete errors.pickUpLocation;
        }

        if (state.differentDropOffLocation && !state.dropOffLocation) {
            errors.dropOffLocation = true;
        } else {
            delete errors.dropOffLocation;
        }

        setState(prevState => {
            return {
                ...prevState,
                errors
            };
        });

        if (!errors.pickUpLocation && !errors.dropOffLocation) {
            ReactGA.event({
                category: "Front",
                action: "Click"
            });
            ReactPixel.track("Search", []);

            setState(prevState => {
                return {
                    ...prevState,
                    isLoading: true
                };
            });

            let searchDetails = {
                page: "cars",
                differentDropOffLocation: state.differentDropOffLocation,
                source: getIATACode(state.pickUpLocation),
                destination: state.differentDropOffLocation
                    ? getIATACode(state.dropOffLocation)
                    : null,
                startDate: getDateString(state.pickUpDate),
                startTime: getDateString(state.pickUpTime, "H:i"),
                endDate: getDateString(state.dropOffDate),
                endTime: getDateString(state.pickUpTime, "H:i")
            };

            const customHeaders = {
                "content-type": "application/json"
            };

            axios
                .post(
                    "https://calltotravel.com/api/searches/add",
                    searchDetails
                )
                .then(response => {
                    console.log(response);
                    console.log(response.data);
                    setState(prevState => {
                        return { ...prevState, changeOfferId: response.data };
                    });
                });
        }
    };

    return state.changeOfferId ? (
        <Redirect to={"/offer?id=" + state.changeOfferId} push={true} />
    ) : (
        <form onSubmit={formSubmitHandler}>
            <h1 className={"shadowed"}>Search Cars</h1>
            <Row className={"mt-4"}>
                <Col lg={4}>
                    <Row>
                        <Col>
                            <FormGroup title={"Pick Up Location"}>
                                <AutoComplete
                                    className={"w-100"}
                                    inputClassName={"w-100"}
                                    placeholder={"City or airport code"}
                                    value={state.pickUpLocationInput}
                                    itemTemplate={suggestTemplate}
                                    onChange={pickUpOnChangeHandler}
                                    onSelect={pickUpOnSelectHandler}
                                    suggestions={state.suggestions}
                                    completeMethod={suggest}
                                />
                            </FormGroup>
                        </Col>
                        {state.differentDropOffLocation && (
                            <Col>
                                <FormGroup title={"Drop Off Location"}>
                                    <AutoComplete
                                        className={"w-100"}
                                        inputClassName={"w-100"}
                                        placeholder={"City or airport code"}
                                        value={state.dropOffLocationInput}
                                        itemTemplate={suggestTemplate}
                                        onChange={dropOffOnChangeHandler}
                                        onSelect={dropOffOnSelectHandler}
                                        suggestions={state.suggestions}
                                        completeMethod={suggest}
                                    />
                                </FormGroup>
                            </Col>
                        )}
                    </Row>
                </Col>

                <Col xs={6} lg={2}>
                    <FormGroup title={"Pick Up Date"}>
                        <Calendar
                            className={"w-100"}
                            showIcon={true}
                            minDate={state.pickUpMinDate}
                            value={state.pickUpDate}
                            readOnlyInput={true}
                            onChange={e => {
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        pickUpDate: e.value
                                    };
                                });

                                if (e.value > state.dropOffDate) {
                                    setState(prevState => {
                                        return {
                                            ...prevState,
                                            dropOffDate: e.value
                                        };
                                    });
                                }
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={6} lg={2}>
                    <FormGroup title={"Time"}>
                        <Calendar
                            className={"w-100"}
                            showIcon={true}
                            value={state.pickUpTime}
                            onChange={e =>
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        pickUpTime: e.value
                                    };
                                })
                            }
                            timeOnly={true}
                        />
                    </FormGroup>
                </Col>
                <Col xs={6} lg={2}>
                    <FormGroup title={"Drop Off Date"}>
                        <Calendar
                            className={"w-100"}
                            showIcon={true}
                            minDate={state.pickUpDate}
                            value={state.dropOffDate}
                            onChange={e =>
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        dropOffDate: e.value
                                    };
                                })
                            }
                        />
                    </FormGroup>
                </Col>

                <Col xs={6} lg={2}>
                    <FormGroup title={"Time"}>
                        <Calendar
                            className={"w-100"}
                            value={state.dropOffTime}
                            showIcon={true}
                            onChange={e =>
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        dropOffTime: e.value
                                    };
                                })
                            }
                            timeOnly={true}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <div className={"d-flex text-left my-2"}>
                <InputSwitch
                    className={"mr-3"}
                    checked={state.differentDropOffLocation}
                    onChange={e =>
                        setState(prevState => {
                            return {
                                ...prevState,
                                differentDropOffLocation: e.value
                            };
                        })
                    }
                />
                <span className={"shadowed"}>Different Drop Off Location?</span>
            </div>

            <Button
                label="Search"
                icon="pi pi-search"
                className="p-button-raised p-button-rounded my-5 searchBtn"
            />
        </form>
    );
}

export default HomeCars;
