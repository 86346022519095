import React, { useEffect, useRef, useState } from "react";
import { Flip, ToastContainer } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import FormGroup from "./FormGroup";
import { Dropdown } from "primereact/dropdown";
import TextField from "@material-ui/core/TextField";
import ButtonMaterialUI from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { FaPhone, FaTimes } from "react-icons/all";

const destinations = [
    { label: "Any Destination", value: "" },
    { label: "Africa", value: "Africa" },
    { label: "Alaska", value: "Alaska" },
    { label: "Asia", value: "Asia" },
    { label: "Australia / New Zealand", value: "Australia / New Zealand" },
    { label: "Bahamas", value: "Bahamas" },
    { label: "Bermuda", value: "Bermuda" },
    { label: "Canada / New England", value: "Canada / New England" },
    { label: "Caribbean", value: "Caribbean" },
    { label: "Central America", value: "Central America" },
    { label: "Europe", value: "Europe" },
    { label: "Hawaii", value: "Hawaii" },
    { label: "Mexico", value: "Mexico" },
    { label: "Middle East", value: "Middle East" },
    { label: "Multi-Country Rivers", value: "Multi-Country Rivers" },
    { label: "Other", value: "Other" },
    { label: "Pacific Coastal", value: "Pacific Coastal" },
    { label: "Panama Canal", value: "Panama Canal" },
    { label: "South America", value: "South America" },
    { label: "South Pacific", value: "South Pacific" },
    { label: "Transatlantic", value: "Transatlantic" }
];

const getDepartures = () => {
    let dates = [new Date()];
    for (let i = 1; i < 24; i++) {
        let date = new Date();
        date.setMonth(date.getMonth() + i);
        date.setDate(1);
        dates.push(date);
    }

    return dates;
};

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

const getDateString = (date, format = "mm/dd/yyyy") => {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
        dd = "0" + dd;
    }

    if (mm < 10) {
        mm = "0" + mm;
    }

    switch (format) {
        case "mm/dd/yyyy":
            return mm + "/" + dd + "/" + yyyy;
        case "dd/mm/yyyy":
            return dd + "/" + mm + "/" + yyyy;
        case "M yyyy":
            let M = months[mm - 1];
            return M + " " + yyyy;
    }
};

const getDeparturesOptions = () => {
    let dates = getDepartures();
    let departures = [{ label: "Any Time", value: "" }];
    for (let i = 0; i < dates.length; i++) {
        departures.push({
            label: getDateString(dates[i], "M yyyy"),
            value: getDateString(dates[i])
        });
    }
    return departures;
};

const FormButton = withStyles(() => ({
    root: {
        color: "white",
        backgroundColor: "#5eb855",
        "&:hover": {
            backgroundColor: "#5eb855"
        }
    }
}))(ButtonMaterialUI);

const cruiseLines = [
    { label: "Any Cruise Line", value: "" },
    { label: "A-Rosa", value: "A-Rosa" },
    { label: "Abercrombie & Kent", value: "Abercrombie & Kent" },
    { label: "AIDA Cruises", value: "AIDA Cruises" },
    { label: "AmaWaterways", value: "AmaWaterways" },
    { label: "American Cruise Line", value: "American Cruise Line" },
    { label: "APT River Cruises", value: "APT River Cruises" },
    { label: "Avalon Waterways", value: "Avalon Waterways" },
    { label: "Azamara Club Cruises", value: "Azamara Club Cruises" },
    {
        label: "Bahamas Paradise Cruise Line",
        value: "Bahamas Paradise Cruise Line"
    },
    {
        label: "Blount - Small Ship Adventures",
        value: "Blount - Small Ship Adventures"
    },
    { label: "Carnival Cruise Lines", value: "Carnival Cruise Lines" },
    { label: "Celebration Cruise Line", value: "Celebration Cruise Line" },
    { label: "Celebrity Cruises", value: "Celebrity Cruises" },
    { label: "Celestyal Cruises", value: "Celestyal Cruises" },
    { label: "Costa Cruise Lines", value: "Costa Cruise Lines" },
    { label: "CroisiEurope", value: "CroisiEurope" },
    { label: "Cruceros Australis", value: "Cruceros Australis" },
    { label: "Crystal Cruises", value: "Crystal Cruises" },
    { label: "Cunard Cruises", value: "Cunard Cruises" },
    { label: "Disney Cruise Line", value: "Disney Cruise Line" },
    { label: "Emerald Waterways", value: "Emerald Waterways" },
    { label: "Fathom", value: "Fathom" },
    { label: "Fred Olsen Cruise Lines", value: "Fred Olsen Cruise Lines" },
    {
        label: "Galapagos Tours and Cruises",
        value: "Galapagos Tours and Cruises"
    },
    { label: "Haimark", value: "Haimark" },
    { label: "Hapag-Lloyd", value: "Hapag-Lloyd" },
    { label: "Holland America Line", value: "Holland America Line" },
    { label: "Hurtigruten", value: "Hurtigruten" },
    {
        label: "Lindblad Special Expeditions",
        value: "Lindblad Special Expeditions"
    },
    { label: "MSC Cruises", value: "MSC Cruises" },
    { label: "Norwegian Cruise Line", value: "Norwegian Cruise Line" },
    { label: "Ocean Hunter Palau", value: "Ocean Hunter Palau" },
    { label: "Ocean Star Cruises", value: "Ocean Star Cruises" },
    { label: "Oceania Cruises", value: "Oceania Cruises" },
    { label: "P & O Cruises", value: "P & O Cruises" },
    { label: "P&O Australia", value: "P&O Australia" },
    { label: "Paul Gauguin Cruises", value: "Paul Gauguin Cruises" },
    { label: "Ponant Yacht Cruises", value: "Ponant Yacht Cruises" },
    { label: "Princess Cruises", value: "Princess Cruises" },
    { label: "Pullmantur Cruises", value: "Pullmantur Cruises" },
    { label: "Quark Expeditions", value: "Quark Expeditions" },
    { label: "Quasar Nautica", value: "Quasar Nautica" },
    { label: "Regent Seven Seas", value: "Regent Seven Seas" },
    { label: "Riviera River Cruises", value: "Riviera River Cruises" },
    {
        label: "Royal Caribbean International",
        value: "Royal Caribbean International"
    },
    { label: "Scenic Cruises", value: "Scenic Cruises" },
    { label: "Sea Cloud Cruises", value: "Sea Cloud Cruises" },
    { label: "Seabourn Cruise Line", value: "Seabourn Cruise Line" },
    { label: "SeaDream Yacht Club", value: "SeaDream Yacht Club" },
    { label: "Silversea Cruises", value: "Silversea Cruises" },
    { label: "Star Clippers", value: "Star Clippers" },
    { label: "Swan Hellenic Cruises", value: "Swan Hellenic Cruises" },
    { label: "Tauck Tours", value: "Tauck Tours" },
    { label: "The Boat Company", value: "The Boat Company" },
    { label: "TUI Cruises", value: "TUI Cruises" },
    { label: "U By Uniworld", value: "U By Uniworld" },
    { label: "Un-Cruise Adventures", value: "Un-Cruise Adventures" },
    { label: "Uniworld Cruises", value: "Uniworld Cruises" },
    { label: "Variety Cruises", value: "Variety Cruises" },
    { label: "Viking Cruises", value: "Viking Cruises" },
    { label: "Viking River Cruises", value: "Viking River Cruises" },
    { label: "Virgin Voyages", value: "Virgin Voyages" },
    { label: "Voyages of Discovery", value: "Voyages of Discovery" },
    { label: "Windstar Cruises", value: "Windstar Cruises" }
];

const lengths = [
    { label: "Any Length", value: "" },
    { label: "1-2 Nights", value: "1-2" },
    { label: "3-5 Nights", value: "3-5" },
    { label: "6-9 Nights", value: "6-9" },
    { label: "10+ Nights", value: "10+" }
];

function OfferCruises({
    classes,
    countries,
    countryToFlag,
    findDetectedCountry,
    state,
    offerState,
    setOfferState,
    searchState,
    setSearchState,
    subscriptionState,
    editModalShowHandler,
    editModalCloseHandler,
    subscriptionModalCloseHandler,
    subscriptionNameChangeHandler,
    subscriptionPhoneChangeHandler,
    subscriptionCountryChangeHandler,
    subscriptionEmailChangeHandler,
    formOnSubmitHandler,
    subscriptionFormOnSubmitHandler,
    clickToCallHandler,
    setIsSubmitSuccess,
    isSubmitSuccess,
    setIsFormFocus
}) {
    const [countDown, setCountDown] = useState(null);
    const [modalCountDown, setModalCountDown] = useState(null);
    const customInterval = useRef(null);
    const modalInterval = useRef(null);

    const countDownFunction = () => {
        if (countDown > 0) {
            setCountDown(countDown - 1);
        } else {
            clearInterval(customInterval.current);
        }
    };

    const modalCountDownFunction = () => {
        if (modalCountDown > 0) {
            setModalCountDown(modalCountDown - 1);
        } else {
            subscriptionModalCloseHandler();
            clearInterval(modalInterval.current);
        }
    };

    useEffect(() => {
        if (!isNaN(countDown)) {
            customInterval.current = setInterval(countDownFunction, 1000);
        }

        return () => clearInterval(customInterval.current);
    }, [countDown]);

    useEffect(() => {
        if (!isNaN(modalCountDown)) {
            modalInterval.current = setInterval(modalCountDownFunction, 1000);
        }

        return () => clearInterval(modalInterval.current);
    }, [modalCountDown]);

    useEffect(() => {
        if (isSubmitSuccess) {
            setCountDown(60);
        }
    }, [isSubmitSuccess]);

    useEffect(() => {
        if (state.subscriptionModalOpen) {
            setModalCountDown(300);
        }
    }, [state.subscriptionModalOpen]);

    const renderFeatureList = () => {
        return (
            <ul className={"px-5 d-inline-block text-left feature-list"}>
                <li>
                    {"Guaranteed Cheap Prices to "}
                    <span>{offerState.destination || "Any Destination"}</span>
                </li>
                <li>
                    Call Waiting Time: <span>Near Zero</span>
                </li>

                {offerState.cruiseLine ? (
                    <li>
                        All Cruises by <span> {offerState.cruiseLine}</span> to{" "}
                        <span> {offerState.destination}</span>
                    </li>
                ) : offerState.destination ? (
                    <li>
                        All Cruise Lines to{" "}
                        <span>{offerState.destination}</span>
                    </li>
                ) : (
                    <li>All Cruise Lines</li>
                )}
            </ul>
        );
    };

    const renderModalCountDown = () => {
        const minute = Math.floor(modalCountDown / 60);
        const seconds = Math.floor(modalCountDown % 60);

        return [
            minute >= 10 ? minute : `0${minute}`,
            ":",
            seconds >= 10 ? seconds : `0${seconds}`
        ].join("");
    };

    const departuresOptions = getDeparturesOptions();

    return (
        <div className={"callAdContainer"}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
                transition={Flip}
            />
            <div className={"container"} onClick={editModalShowHandler}>
                <Row className={"mt-md-4 mt-1"}>
                    <Col className={"px-2"} xs={4} sm={5}>
                        <InputText
                            className={"w-100"}
                            value={
                                offerState.destination
                                    ? offerState.destination
                                    : "Any Destination"
                            }
                            onClick={e => {
                                document.body.focus();
                            }}
                        />
                    </Col>
                    <Col className={"px-2"} xs={3}>
                        <InputText
                            className={"w-100"}
                            value={
                                offerState.cruiseLine
                                    ? offerState.cruiseLine
                                    : "Any Cruise Line"
                            }
                            onClick={e => {
                                document.body.focus();
                            }}
                        />
                    </Col>
                    <Col className={"px-2"} xs={3}>
                        <InputText
                            className={"w-100"}
                            value={
                                offerState.departure
                                    ? getDateString(
                                          offerState.departure,
                                          "M yyyy"
                                      )
                                    : "Any Time"
                            }
                            onClick={e => {
                                document.body.focus();
                            }}
                        />
                    </Col>
                    <Col className={"px-2"} xs={2} sm={1}>
                        <Button className={"w-100 h-100"} icon="pi pi-pencil" />
                    </Col>
                </Row>
            </div>

            <Modal
                show={state.editModalOpen}
                onHide={editModalCloseHandler}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className={"text-center"}>
                        Search Cruises
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formOnSubmitHandler}>
                        <Row className={"mt-3d"}>
                            <Col sm={12} md={6}>
                                <FormGroup title={"Destination"}>
                                    <Dropdown
                                        className={"w-100"}
                                        inputClassName={"w-100"}
                                        value={searchState.destination}
                                        options={destinations}
                                        onChange={e => {
                                            setSearchState(prevState => {
                                                return {
                                                    ...prevState,
                                                    destination: e.value
                                                };
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col sm={12} md={6}>
                                <FormGroup title={"Departure"}>
                                    <Dropdown
                                        className={"w-100"}
                                        inputClassName={"w-100"}
                                        value={searchState.departure}
                                        options={departuresOptions}
                                        onChange={e => {
                                            setSearchState(prevState => {
                                                return {
                                                    ...prevState,
                                                    departure: e.value
                                                };
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={6}>
                                <FormGroup title={"Cruise Line"}>
                                    <Dropdown
                                        className={"w-100"}
                                        inputClassName={"w-100"}
                                        value={searchState.cruiseLine}
                                        options={cruiseLines}
                                        onChange={e => {
                                            setSearchState(prevState => {
                                                return {
                                                    ...prevState,
                                                    cruiseLine: e.value
                                                };
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={6}>
                                <FormGroup title={"Length"}>
                                    <Dropdown
                                        className={"w-100"}
                                        inputClassName={"w-100"}
                                        value={searchState.length}
                                        options={lengths}
                                        onChange={e => {
                                            setSearchState(prevState => {
                                                return {
                                                    ...prevState,
                                                    length: e.value
                                                };
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <div className={"my-3 d-flex align-items-center"}>
                            <div className={"upTo70"} />
                            <Button
                                label="Update Search"
                                icon="pi pi-search"
                                className="p-button-raised p-button-rounded searchBtn"
                            />
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={state.subscriptionModalOpen}
                onHide={subscriptionModalCloseHandler}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className={"text-center"}>
                        <div className="subscription-modal-header">
                            <div className="color-red">
                                Time Is Running Out: {renderModalCountDown()}
                            </div>
                            Exclusive Deals For Cruises To{" "}
                            {offerState.destination
                                ? offerState.destination
                                : "Any Destination"}{" "}
                            In{" "}
                            {offerState.departure
                                ? getDateString(offerState.departure, "M yyyy")
                                : "Any Time"}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={"text-center"}>
                    <h6>
                        Our VIP concierge team is available now. We have
                        guaranteed cheap price for cruises to{" "}
                        {offerState.destination
                            ? offerState.destination
                            : "Any Destination"}
                        . We call you in just 1 minute.
                    </h6>
                    <form onSubmit={subscriptionFormOnSubmitHandler}>
                        <div
                            className={[
                                "form-container",
                                isSubmitSuccess ? "form-expanded" : ""
                            ].join(" ")}
                        >
                            <Row>
                                <Col xs={12} md={6} className={"my-2"}>
                                    <TextField
                                        inputProps={{
                                            autocomplete: "name"
                                        }}
                                        autocomplete="name"
                                        className={"w-100"}
                                        error={subscriptionState.errors.name}
                                        id="outlined-error-helper-text"
                                        label="Name*"
                                        variant="outlined"
                                        onChange={subscriptionNameChangeHandler}
                                        value={subscriptionState.name}
                                        onFocus={() => {
                                            setIsFormFocus(true);
                                        }}
                                        onBlur={() => {
                                            setIsFormFocus(false);
                                        }}
                                    />
                                </Col>
                                <Col md={6} xs={12} className={"my-2"}>
                                    <TextField
                                        inputProps={{
                                            autocomplete: "email"
                                        }}
                                        autocomplete="email"
                                        error={subscriptionState.errors.email}
                                        className={"w-100"}
                                        id="outlined-error-helper-text"
                                        label="Email*"
                                        variant="outlined"
                                        value={subscriptionState.email}
                                        onChange={
                                            subscriptionEmailChangeHandler
                                        }
                                        onFocus={() => {
                                            setIsFormFocus(true);
                                        }}
                                        onBlur={() => {
                                            setIsFormFocus(false);
                                        }}
                                    />
                                </Col>
                                <Col xs={12} md={6} className={"my-2"}>
                                    <TextField
                                        inputProps={{
                                            autocomplete: "tel"
                                        }}
                                        autocomplete="tel"
                                        className={"w-100"}
                                        error={
                                            subscriptionState.errors.phoneNumber
                                        }
                                        id="outlined-error-helper-text"
                                        label="Phone Number*"
                                        variant="outlined"
                                        onChange={
                                            subscriptionPhoneChangeHandler
                                        }
                                        value={subscriptionState.phoneNumber}
                                        onFocus={() => {
                                            setIsFormFocus(true);
                                        }}
                                        onBlur={() => {
                                            setIsFormFocus(false);
                                        }}
                                    />
                                </Col>
                            </Row>

                            <ButtonMaterialUI
                                variant="contained"
                                color="primary"
                                className={"w-100"}
                                style={{
                                    marginTop: "10px",
                                    fontSize: 20 + "px",
                                    fontWeight: "bold"
                                }}
                                onClick={subscriptionFormOnSubmitHandler}
                            >
                                CALL ME NOW
                            </ButtonMaterialUI>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <div className={"container container-box my-md-4 my-1"}>
                <div className={"mob-ad text-center my-4"}>
                    <div className={"search-info text-white p-1"}>
                        <h5 className={"pt-3"}>Deals Found For Cruises To</h5>
                        <h2>
                            <span>
                                {offerState.destination
                                    ? offerState.destination
                                    : "Any Destination"}
                            </span>
                        </h2>
                        <h5 className={"m-0 pb-2"}>
                            <span>
                                {offerState.departure
                                    ? getDateString(
                                          offerState.departure,
                                          "M yyyy"
                                      )
                                    : "Any Time"}
                            </span>
                            {offerState.cruiseLine ? (
                                <>
                                    {" "}
                                    <br />
                                    <span>By {offerState.cruiseLine}</span>{" "}
                                </>
                            ) : null}
                        </h5>
                    </div>

                    <div className={"caption"}>
                        <div>
                            <h3 className={"py-2  m-0"}>
                                UNPUBLISHED CRUISE DEALS
                            </h3>
                            <h1 className={"m-0 pb-5 pt-1"}>
                                CALL ONLY DEALS!
                            </h1>
                        </div>
                    </div>

                    <Row>
                        <Col xs={12} md={6}>
                            <div
                                className={
                                    "callDetails mb-4 d-flex justify-content-center align-items-center mx-2"
                                }
                            >
                                <div
                                    className={
                                        "phoneBox d-inline-block p-1 d-flex flex-column text-white"
                                    }
                                    onClick={clickToCallHandler}
                                >
                                    <h1 className={"m-0 p-0"}>
                                        {offerState.phoneNumber}
                                    </h1>
                                    <h6 className={"m-0 p-0"}>Call Now</h6>
                                </div>
                            </div>
                            <div className="desktop-feature-list">
                                {renderFeatureList()}
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="d-flex flex-column justify-content-center p-3 form-container form-body">
                                <div>
                                    {!isSubmitSuccess ? (
                                        <>
                                            {
                                                "Request Callback for Cheap Cruise Prices to "
                                            }
                                            {offerState.destination ||
                                                "Any Destination"}
                                            <div>
                                                <span className="color-red">
                                                    We Call You in 1 Minute
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {
                                                "Your request is submitted successfully. Our team will call you in "
                                            }
                                            <span className="color-red">
                                                {countDown} {" Seconds"}
                                            </span>
                                        </>
                                    )}
                                </div>
                                <div
                                    className={[
                                        "form-container",
                                        isSubmitSuccess ? "form-expanded" : ""
                                    ].join(" ")}
                                >
                                    <form
                                        onSubmit={
                                            subscriptionFormOnSubmitHandler
                                        }
                                    >
                                        <div className="row mx-0">
                                            <Col
                                                xs={12}
                                                md={6}
                                                className={"my-2 px-2"}
                                            >
                                                <TextField
                                                    inputProps={{
                                                        autocomplete: "name"
                                                    }}
                                                    autocomplete="name"
                                                    className={"w-100"}
                                                    error={
                                                        subscriptionState.errors
                                                            .name
                                                    }
                                                    id="outlined-error-helper-text"
                                                    label="Name*"
                                                    variant="outlined"
                                                    onChange={
                                                        subscriptionNameChangeHandler
                                                    }
                                                    value={
                                                        subscriptionState.name
                                                    }
                                                    onFocus={() => {
                                                        setIsFormFocus(true);
                                                    }}
                                                    onBlur={() => {
                                                        setIsFormFocus(false);
                                                    }}
                                                />
                                            </Col>
                                            <Col
                                                xs={12}
                                                md={6}
                                                className={"my-2 px-2"}
                                            >
                                                <TextField
                                                    inputProps={{
                                                        autocomplete: "email"
                                                    }}
                                                    autocomplete="email"
                                                    error={
                                                        subscriptionState.errors
                                                            .email
                                                    }
                                                    className={"w-100"}
                                                    id="outlined-error-helper-text"
                                                    label="Email Address*"
                                                    variant="outlined"
                                                    value={
                                                        subscriptionState.email
                                                    }
                                                    onChange={
                                                        subscriptionEmailChangeHandler
                                                    }
                                                    onFocus={() => {
                                                        setIsFormFocus(true);
                                                    }}
                                                    onBlur={() => {
                                                        setIsFormFocus(false);
                                                    }}
                                                />
                                            </Col>
                                            <Col
                                                xs={12}
                                                md={6}
                                                className={"my-2 px-2"}
                                            >
                                                <TextField
                                                    inputProps={{
                                                        autocomplete: "tel"
                                                    }}
                                                    autocomplete="tel"
                                                    className={"w-100"}
                                                    error={
                                                        subscriptionState.errors
                                                            .phoneNumber
                                                    }
                                                    id="outlined-error-helper-text"
                                                    label="Phone Number*"
                                                    variant="outlined"
                                                    onChange={
                                                        subscriptionPhoneChangeHandler
                                                    }
                                                    value={
                                                        subscriptionState.phoneNumber
                                                    }
                                                    onFocus={() => {
                                                        setIsFormFocus(true);
                                                    }}
                                                    onBlur={() => {
                                                        setIsFormFocus(false);
                                                    }}
                                                />
                                            </Col>
                                        </div>
                                        <div className="row mx-0">
                                            <Col
                                                xs={12}
                                                md={12}
                                                className={"my-2 px-2"}
                                            >
                                                <FormButton
                                                    variant="contained"
                                                    color="primary"
                                                    className={"w-100"}
                                                    style={{
                                                        marginTop: "10px",
                                                        fontSize: 20 + "px",
                                                        fontWeight: "bold"
                                                    }}
                                                    onClick={
                                                        subscriptionFormOnSubmitHandler
                                                    }
                                                >
                                                    CALL ME NOW
                                                </FormButton>
                                            </Col>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="mobile-feature-list">
                                {renderFeatureList()}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            {offerState.footerCallBoxShown && (
                <div
                    className={
                        "footer-call-box d-flex justify-content-between align-items-center position-fixed w-100"
                    }
                >
                    <FaTimes
                        onClick={() => {
                            setOfferState(prevState => {
                                return {
                                    ...prevState,
                                    footerCallBoxShown: false
                                };
                            });
                        }}
                        className={"mx-3"}
                    />
                    <h6 className={"call-text p-0 m-0"}>
                        <span onClick={clickToCallHandler}>
                            {offerState.phoneNumber}
                        </span>
                        : Unpublished Cruise Rates{" "}
                        {offerState.destination
                            ? "To " + offerState.destination
                            : null}
                    </h6>
                    <div className={"call-btn"} onClick={clickToCallHandler}>
                        <FaPhone
                            className={"phone-icon"}
                            onClick={clickToCallHandler}
                        />
                    </div>
                </div>
            )}
            {!offerState.footerCallBoxShown && (
                <div
                    className={"call-btn call-btn-alone"}
                    onClick={clickToCallHandler}
                >
                    <FaPhone
                        className={"phone-icon"}
                        onClick={clickToCallHandler}
                    />
                </div>
            )}
        </div>
    );
}

export default OfferCruises;
