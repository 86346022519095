import React from "react";
import Footer from "./footer";

function TermsOfService() {
    return (
        <>
            <div className={"container mt-5 py-5"}>
                <h1>Privacy Policy</h1>
                <h3>1. Introduction</h3>
                <p>
                    1.1 We are committed to safeguarding the privacy of our website visitors and service users.
                </p>
                <p>
                    1.2 This policy applies where we are acting as a data controller with respect to the
                    personal data of our website visitors and service users; in other words, where we determine
                    the purposes and means of the processing of that personal data.
                </p>
                <p>
                    1.3 We use cookies on our website. Insofar as those cookies are not strictly necessary for
                    the provision of our website and services, we may ask you to consent to our use of cookies
                    when you first visit our website.
                </p>
                <p>
                    1.4 If you do not accept using the cookies by our website and the third party vendors and ad
                    networks we use, please manage your preferences by using the Cookie Consent Tool or
                    disabling the cookies in your browser. You may find more information about cookies and
                    managing them by using the following link: http://www.allaboutcookies.org/
                </p>
                <p>
                    1.5 In this policy, "we", "us" and "our" refer to CallToTravel.
                </p>
                <h3>2. Credit</h3>
                <p>
                    2.1 This document was created using a template from SEQ Legal.
                </p>
                <h3>
                    3. How we use your personal data
                </h3>
                <p className="m-0">
                    3.1 In this Section 3 we have set out:
                </p>
                <p className="m-0">
                    (a) the general categories of personal data that we may process;
                </p>
                <p className="m-0">
                    (b) in the case of personal data that we did not obtain directly from you, the source and
                    specific categories of that data;
                </p>
                <p className="m-0">
                    (c) the purposes for which we may process personal data; and
                </p>
                <p>
                    (d) the legal bases of the processing.
                </p>
                <p>
                    3.2 We may process data about your use of our website and services ("usage data"). The usage
                    data may include your country, browser type and version, operating system, referral source,
                    UTM trackings, length of visit, page views and website navigation paths, as well as
                    information about the timing, frequency and pattern of your service use. The source of the
                    usage data is our analytics tracking system, which does not save any personal identifiable
                    data and stores all the data in the aggregated format making it almost impossible to
                    identify or lead to identifying the end user. This usage data may be processed for the
                    purposes of analysing the use of the website and services, improving the website and
                    monitoring the traffic volume. The legal basis for this processing is our legitimate
                    interests, namely monitoring and improving our website and services and analyzing the
                    traffic.
                </p>
                <p>
                    3.3 We may store the web server logs. A server log is a simple text file which records all
                    the activity on the server. A server log may include the browser type and version, operating
                    system, referral source, the path to the file. Our web server logs do not store your IP
                    address, as we use the Proxy (Cloudflare) for security reasons and their IP address is
                    stored instead. This usage data may be processed for providing security of the server,
                    analysing the use of the server, monitoring the suspicious traffic on our websites. The
                    legal basis for this processing is our legitimate interests, namely monitoring and improving
                    the security of our website and services.
                </p>
                <p>
                    3.4 We may process any of your personal data identified in this policy where necessary for
                    the establishment, exercise or defence of legal claims, whether in court proceedings or in
                    an administrative or out-of-court procedure. The legal basis for this processing is our
                    legitimate interests, namely the protection and assertion of our legal rights, your legal
                    rights and the legal rights of others.
                </p>
                <p>
                    3.5 We may process any of your personal data identified in this policy where necessary for
                    the purposes of obtaining or maintaining insurance coverage, managing risks, or obtaining
                    professional advice. The legal basis for this processing is our legitimate interests, namely
                    the proper protection of our business against risks.
                </p>
                <p>
                    3.6 Please do not supply any other person's personal data to us, unless we prompt you to do
                    so.
                </p>

                <h3>
                    4. Providing your personal data to others
                </h3>
                <p>
                    4.1 We may disclose an aggregated data which does not include any personal identifiable to
                    any member of our company, our contractual partners, insofar as reasonably necessary for the
                    purposes, and on the legal bases, set out in this policy.

                </p>
                <p>
                    4.2 We may disclose your personal data to our insurers and/or professional advisers insofar
                    as reasonably necessary for the purposes of obtaining or maintaining insurance coverage,
                    managing risks, obtaining professional advice, or the establishment, exercise or defence of
                    legal claims, whether in court proceedings or in an administrative or out-of-court
                    procedure.
                </p>
                <p>
                    4.3 In addition to the specific disclosures of personal data set out in this Section 4, we
                    may disclose your personal data where such disclosure is necessary for compliance with a
                    legal obligation to which we are subject, or in order to protect your vital interests or the
                    vital interests of another natural person. We may also disclose your personal data where
                    such disclosure is necessary for the establishment, exercise or defence of legal claims,
                    whether in court proceedings or in an administrative or out-of-court procedure.
                </p>
                <h3>
                    5. International transfers of your personal data

                </h3>
                <p>
                    5.1 In this Section 5, we provide information about the circumstances in which your personal
                    data may be transferred to countries outside the European Economic Area (EEA).
                </p>
                <p>
                    5.2 We use the Proxy services of Cloudflare in order to provide a high level of security and
                    safety on our websites as well as improve the loading speed of the websites. Cloudflare is
                    based in the US and their hosting facilities are worldwide. Learn more at
                    https://www.cloudflare.com/security-policy/ and https://www.cloudflare.com/privacyshield/
                </p>
                <p>
                    5.3 You acknowledge that the personal data that you submit for publication through our
                    website or services may be available, via the internet, around the world. We cannot prevent
                    the use (or misuse) of such personal data by others.
                </p>
                <h3>
                    6. Retaining and deleting personal data
                </h3>
                <p>
                    6.1 This Section 6 sets out our data retention policies and procedure, which are designed to
                    help ensure that we comply with our legal obligations in relation to the retention and
                    deletion of personal data.
                </p>
                <p>
                    6.2 Personal data that we process for any purpose or purposes shall not be kept for longer
                    than is necessary for that purpose or those purposes.
                </p>
                <p>
                    6.3 Notwithstanding the other provisions of this Section 6, we may retain your personal data
                    where such retention is necessary for compliance with a legal obligation to which we are
                    subject, or in order to protect your vital interests or the vital interests of another
                    natural person.
                </p>
                <h3>
                    7. Amendments
                </h3>
                <p>
                    7.1 We may update this policy from time to time by publishing a new version on our website.
                </p>
                <p>
                    7.2 You should check this page occasionally to ensure you are happy with any changes to this
                    policy.
                </p>
                <p>
                    7.3 We may notify you of changes to this policy through the notification system on our
                    website.
                </p>
                <h3>
                    8. Your rights
                </h3>
                <p>
                    8.1 In this Section 8, we have summarised the rights that you have under data protection
                    law. Some of the rights are complex, and not all of the details have been included in our
                    summaries. Accordingly, you should read the relevant laws and guidance from the regulatory
                    authorities for a full explanation of these rights.
                </p>
                <p className="m-0">
                    8.2 Your principal rights under data protection law are:
                </p>
                <p className="m-0">
                    (a) the right to access;
                </p>
                <p className="m-0">
                    (b) the right to rectification;
                </p>
                <p className="m-0">
                    (c) the right to erasure;
                </p>
                <p className="m-0">
                    (d) the right to restrict processing;
                </p>
                <p className="m-0">
                    (e) the right to object to processing;
                </p>
                <p className="m-0">
                    (f) the right to data portability;
                </p>
                <p className="m-0">
                    (g) the right to complain to a supervisory authority; and
                </p>
                <p>
                    (h) the right to withdraw consent.
                </p>
                <p>
                    8.3 You have the right to confirmation as to whether or not we process your personal data
                    and, where we do, access to the personal data, together with certain additional information.
                    That additional information includes details of the purposes of the processing, the
                    categories of personal data concerned and the recipients of the personal data. Providing the
                    rights and freedoms of others are not affected, we will supply to you a copy of your
                    personal data. The first copy will be provided free of charge, but additional copies may be
                    subject to a reasonable fee. Please, contact us at Info <span dangerouslySetInnerHTML={{ __html: '{at}' }} /> CallToTravel <span dangerouslySetInnerHTML={{ __html: '{dot}' }} /> Com if you want to
                    request a copy of the personal data.
                </p>
                <p>
                    8.4 You have the right to have any inaccurate personal data about you rectified and, taking
                    into account the purposes of the processing, to have any incomplete personal data about you
                    completed.
                </p>
                <p>
                    8.5 In some circumstances you have the right to the erasure of your personal data without
                    undue delay. Those circumstances include: the personal data are no longer necessary in
                    relation to the purposes for which they were collected or otherwise processed; you withdraw
                    consent to consent-based processing; you object to the processing under certain rules of
                    applicable data protection law; the processing is for direct marketing purposes; and the
                    personal data have been unlawfully processed. However, there are exclusions of the right to
                    erasure. The general exclusions include where processing is necessary: for exercising the
                    right of freedom of expression and information; for compliance with a legal obligation; or
                    for the establishment, exercise or defence of legal claims.
                </p>
                <p>
                    8.6 In some circumstances you have the right to restrict the processing of your personal
                    data. Those circumstances are: you contest the accuracy of the personal data; processing is
                    unlawful but you oppose erasure; we no longer need the personal data for the purposes of our
                    processing, but you require personal data for the establishment, exercise or defence of
                    legal claims; and you have objected to processing, pending the verification of that
                    objection. Where processing has been restricted on this basis, we may continue to store your
                    personal data. However, we will only otherwise process it: with your consent; for the
                    establishment, exercise or defence of legal claims; for the protection of the rights of
                    another natural or legal person; or for reasons of important public interest.
                </p>
                <p>
                    8.7 You have the right to object to our processing of your personal data on grounds relating
                    to your particular situation, but only to the extent that the legal basis for the processing
                    is that the processing is necessary for: the performance of a task carried out in the public
                    interest or in the exercise of any official authority vested in us; or the purposes of the
                    legitimate interests pursued by us or by a third party. If you make such an objection, we
                    will cease to process the personal information unless we can demonstrate compelling
                    legitimate grounds for the processing which override your interests, rights and freedoms, or
                    the processing is for the establishment, exercise or defence of legal claims.
                </p>
                <p>
                    8.8 You have the right to object to our processing of your personal data for direct
                    marketing purposes including profiling for direct marketing purposes. If you make such an
                    objection, we will cease to process your personal data for this purpose.
                </p>
                <p>
                    8.9 You have the right to object to our processing of your personal data for scientific or
                    historical research purposes or statistical purposes on grounds relating to your particular
                    situation, unless the processing is necessary for the performance of a task carried out for
                    reasons of public interest.
                </p>
                <p className="m-0">
                    8.10 To the extent that the legal basis for our processing of your personal data is:
                </p>
                <p className="m-0">
                    (a) consent; or
                </p>
                <p>
                    (b) that the processing is necessary for the performance of a contract to which you are
                    party or in order to take steps at your request prior to entering into a contract,
                </p>
                <p>
                    and such processing is carried out by automated means, you have the right to receive your
                    personal data from us in a structured, commonly used and machine-readable format. However,
                    this right does not apply where it would adversely affect the rights and freedoms of others.
                </p>
                <p>
                    8.11 If you consider that our processing of your personal information infringes data
                    protection laws, you have a legal right to lodge a complaint with a supervisory authority
                    responsible for data protection. You may do so in the EU member state of your habitual
                    residence, your place of work or the place of the alleged infringement.
                </p>
                <p>
                    8.12 To the extent that the legal basis for our processing of your personal information is
                    consent, you have the right to withdraw that consent at any time. Withdrawal will not affect
                    the lawfulness of processing before the withdrawal.
                </p>
                <p>
                    8.13 You may exercise any of your rights in relation to your personal data by contacting us
                    at Info <span dangerouslySetInnerHTML={{ __html: '{at}' }} /> CallToTravel <span dangerouslySetInnerHTML={{ __html: '{dot}' }} /> Com.
                </p>
                <h3>
                    9. About cookies
                </h3>
                <p>
                    9.1 A cookie is a file containing an identifier (a string of letters and numbers) that is
                    sent by a web server to a web browser and is stored by the browser. The identifier is then
                    sent back to the server each time the browser requests a page from the server.
                </p>
                <p>
                    9.2 Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie
                    will be stored by a web browser and will remain valid until its set expiry date, unless
                    deleted by the user before the expiry date; a session cookie, on the other hand, will expire
                    at the end of the user session, when the web browser is closed.
                </p>
                <p>
                    9.3 Cookies do not typically contain any information that personally identifies a user, but
                    personal information that we store about you may be linked to the information stored in and
                    obtained from cookies.
                </p>
                <h3>
                    10. Cookies that we use
                </h3>
                <p className="m-0">
                    10.1 We use cookies for the following purposes:
                </p>
                <p className="m-0">
                    (a) advertising - we use cookies to track and analyze the performance of the advertising campaigns, analyze the traffic sources, display advertisements that will be relevant to you and help to direct you to the appropriate part of our website. Cookies used for this purpose are: source, medium, utm_campaign;
                </p>
                <p>
                    (b) cookie consent - we use cookies to store your preferences in relation to the use of cookies. Cookies used for this purpose are: hide_cookiesbar.
                </p>
                <h3>
                    11. Third Party Cookies
                </h3>
                <p>
                    11.1 Our service providers use cookies and those cookies may be stored on your computer when you visit our website.
                </p>
                <p>
                    11.2 Ad networks and third party vendors use cookies to serve advertisements based on your previous visits to our website or other websites. You may opt out of some third-party vendorsí uses of cookies for personalized advertising by visiting www.aboutads.info/choices/ or http://www.youronlinechoices.eu
                </p>
                <p className="m-0">
                    11.3 Some of the third-party vendors and ad networks serving ads on our site are:
                </p>
                <p>
                    Google AdSense/AdX, Google's use of advertising cookies enables it and its partners to serve ads on our website based on your visit to our site and/or other sites on the Internet. List of the vendors that are certified for third-party ads - https://support.google.com/dfp_premium/answer/94149. Learn more at https://policies.google.com/privacy, and to opt out from the use of cookies for personalized advertising, visit https://www.google.com/settings/ads
                </p>
                <p>
                    11.4 Some of the third-party service providers we use to analyse the use of our website, monitor the traffic levels and track the performance of the advertising campaigns are:
                </p>
                <p className="m-0">
                    Google Analytics ñ web analytics service that tracks and reports website traffic. Learn more at https://www.google.com/policies/privacy/. The relevant cookies are: __utma, __utmb, __utmc, ga, gat, gid
                </p>
                <p className="m-0">
                    AddThis ñ social sharing widget that allows to track how many times the content has been shared in the social media networks. Learn more at http://www.addthis.com/privacy
                </p>
                <p className="m-0">
                    Yandex Metrica ñ Learn more at https://metrica.yandex.com/about/info/privacy-policy, and to opt out, visit https://yandex.com/support/metrica/general/opt-out.xml
                </p>
                <p>
                    Taboola Pixel ñ advertising platform that provides tracking pixel (collects conversion data, performs platform analytics, etc.) Learn more at https://www.taboola.com/privacy-policy
                </p>
                <p>
                    11.5 We use Cloudflare to improve the performance and the security of the website. Learn more at https://www.cloudflare.com/security-policy/
                </p>
                <h3>
                    12. Managing cookies
                </h3>
                <p className="m-0">
                    12.1 Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:
                </p>
                <p className="m-0">
                    (a) https://support.google.com/chrome/answer/95647?hl=en (Chrome);
                </p>
                <p className="m-0">
                    (b) https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences (Firefox);
                </p>
                <p className="m-0">
                    (c) http://www.opera.com/help/tutorials/security/cookies/ (Opera);
                </p>
                <p className="m-0">
                    (d) https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies (Internet Explorer);
                </p>
                <p className="m-0">
                    (e) https://support.apple.com/kb/Ph31411 (Safari); and
                </p>
                <p>
                    (f) https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy (Edge).
                </p>

                <p>
                    12.2 Blocking all cookies may have a negative impact upon the usability of many websites.

                </p>
                <p>
                    12.3 If you block cookies, you may not be able to use all the features on our website.
                </p>
                <h3>
                    13. Cookie preferences
                </h3>
                <p>
                    13.1 You can manage your preferences relating to the use of cookies on our website by using the Cookie Consent Tool.
                </p>
                <h3>
                    If you have any questions, please, do not hesitate to contact us at
                    Info <span dangerouslySetInnerHTML={{ __html: '{at}' }} /> CallToTravel <span dangerouslySetInnerHTML={{ __html: '{dot}' }} /> Com.
                </h3>
            </div>
            <Footer />
        </>

    );
}

export default TermsOfService
