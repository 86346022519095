import React from "react";
import { NavLink } from "react-router-dom";
import { Col } from "react-bootstrap";

function TopTabMenuItem(props) {
    return (
        <Col className={"menuItem"}>
            <NavLink className={"d-block py-1"} to={"/" + props.sectionLink}>
                {props.sectionIcon}
                <span className={"d-block"}>{props.sectionName}</span>
            </NavLink>
        </Col>
    );
}

export default TopTabMenuItem;
