import React, { useContext, useReducer } from "react";
import { AppContext } from "../App";
import TopTabMenu from "./TopTabMenu";
import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";
import HomeFlights from "./HomeFlights";
import HomeHotels from "./HomeHotels";
import HomeCars from "./HomeCars";
import HomeCruises from "./HomeCruises";
import Footer from "./footer";

function Main(props) {
    const appContext = useContext(AppContext);

    let match = useRouteMatch();

    return (
        <>
            <div className={"bg bg-home py-5 text-white"}>
                <div className={"container"}>
                    <TopTabMenu />
                    <Switch>
                        <Route path={match.path + "flights/"}>
                            <HomeFlights />
                        </Route>
                        <Route path={match.path + "hotels/"}>
                            <HomeHotels />
                        </Route>
                        <Route path={match.path + "cruises/"}>
                            <HomeCruises />
                        </Route>
                        <Route path={match.path + "cars/"}>
                            <HomeCars />
                        </Route>
                        <Route path={match.path}>
                            <Redirect to={match.path + "flights"}></Redirect>
                        </Route>
                    </Switch>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Main;
